import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

// Utilities 
import Fetching from "../../utilities/fetching";

// View components
import Encryption from "../../utilities/encryption";
import Submit from "../../components/questionnaire/step-submit";

const SubmitContainer = (props) => {

    // Track state for this screen
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState(false);
    const [error, setError] = useState(false);

    // Cookie / auth
    // let encSecret = process.env.REACT_APP_ENC_SECRET;
    const [accCookie, setCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Submit the form data via fetch 
    const loadContent = ( ) => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/questionnaire/report", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            setLoading(false);
            if(data.rslt === 'success' ){
                
                setDetails(data.detail);
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error displaying your responses. Please refresh the page to try again", style: "red" });
        });

    }

    // Submit the form data via fetch 
    const submitReport = ( e ) => {

        if (e) e.preventDefault();

        // Clear out any errors
        setError(false);

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/questionnaire/submit", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.token,
                'api-key': authInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Redirect the form to the next step
                props.updateStep("nxt");
                scrollToTop();
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error submitting your report. Please refresh the page and try again", style: "red" });
        });

    }

    const goBack = ( e ) => {
        e.preventDefault();
        props.updateStep("back", false);
        scrollToTop();
    }

    const scrollToTop = () => {
        window.scrollTo(0, 240);
    }

    /* eslint-disable */
    useEffect(() => {
        loadContent();
    }, []);
    useEffect(() => {
        if (props.doSubmit) {
            submitReport(false);
        }
    }, [props.doSubmit]);
    /* eslint-enable */

    return (
        <Submit
            loading={loading}
            details={details}
            error={error}
            goBack={goBack}
            submitReport={submitReport}
            { ...props }
        />
    );
}

export default SubmitContainer;
