// Core react and plugins
import React from 'react';
import { Helmet } from "react-helmet";

import ValidationError from '../../common/forms/validation-error';
import Error from '../../common/forms/error';

const CreateCoach = (props) => {

    return (
        <div className="content admin-content">
            <Helmet>
                <title>View report :: The Coaching Hub</title>
            </Helmet>
            
            <div className="width-9 centred">
                <h1>{ props.coach.firstName !== "" ? "Update coach" : "Create coach" }</h1>

                <Error error={ props.error } />

                <form>
                
                    <div className="input text">
                        <label htmlFor="firstName">First name</label>
                        <input type="text" name="firstName" id="firstName" value={ props.coach.firstName } onChange={ (e) => props.handleUpdate(e) } />
                        <ValidationError errors={ props.valErrors } fieldName="FirstName" />    
                    </div>

                    <div className="input text">
                        <label htmlFor="lastName">Last name</label>
                        <input type="text" name="lastName" id="lastName" value={props.coach.lastName} onChange={(e) => props.handleUpdate(e)} />
                        <ValidationError errors={props.valErrors} fieldName="LastName" />    
                    </div>

                    <div className="input text email">
                        <label htmlFor="emailAddress">Email address</label>
                        <input type="text" name="emailAddress" id="emailAddress" value={props.coach.emailAddress} onChange={(e) => props.handleUpdate(e)} />
                        <ValidationError errors={props.valErrors} fieldName="EmailAddress" />    
                    </div>

                    <div className="input textarea">
                        <label htmlFor="summary">Brief summary of key areas of expertise</label>
                        <textarea type="text" name="summary" id="summary" value={props.coach.summary} onChange={(e) => props.handleUpdate(e)}></textarea>
                        <ValidationError errors={props.valErrors} fieldName="Summary" />    
                    </div>

                    <button className="btn primary" onClick={(e) => props.handleSave(e) }>Save coach</button>

                </form>
            </div>

        </div>
    );
}

export default CreateCoach;
