// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';
import CreateClient from '../../../components/admin/clients/create';

// View components

const CreateClientContainer = (props) => {

    const urlPath = useParams();
    var navigate = useNavigate();

    let isClientEdit = true;
    let initialBtnText = "Edit Client"
    if(typeof urlPath != "undefined" && urlPath.clientRef === 'create'){
        isClientEdit = false;
        initialBtnText = "Create Client"
    }

    // State management
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [error, setError] = useState(false); // eslint-disable-line
    const [details, setDetails] = useState({ clientName: "", licences: "", domains: [""] });
    const [btnTxt, setBtnTxt] = useState(initialBtnText);
    const [valErrors, setValErrors] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    
    const redirectUrl = "/intro"

    // Handle the field updates 
    const handleUpdate = (e) => {
        var newDetails = { ...details };
        if (e.target.name.indexOf("domain") > -1) {
            var index = e.target.name.replace("domain", "");
            newDetails.domains[index] = e.target.value;
        } else {
            newDetails[e.target.name] = e.target.value;
        }
        console.log(newDetails);
        setDetails(newDetails);
    }

    // Add a domain 
    const addDomain = (e) => {
        e.preventDefault();
        var newDetails = { ...details };
        newDetails.domains.push("");
        setDetails(newDetails);
    }

    // Remove a domain 
    const removeDomain = (e, index) => {
        e.preventDefault();
        var newDetails = { ...details };
        newDetails.domains.splice(index, 1);
        setDetails(newDetails);
    }

    // Submit the details for saving
    const saveClient = ( e ) => {

        e.preventDefault();
        setError(false);
        setValErrors(false);

        var nbtn = (!isClientEdit) ? "Creating Client..." : "Editing Client...";
        setBtnTxt(nbtn);

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Set the license no to a number
        details.licences = parseInt(details.licences);

        var url = '/clients/' + urlPath.clientRef;
        var methodType = (!isClientEdit) ? 'POST' : 'PUT';
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: methodType ,
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(details)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            var nbtn;
            nbtn = (isClientEdit) ? "Edit Client" : "Create Client";
            setBtnTxt(nbtn);
            if(data.rslt !== 'success'){
                setError({ msg: data.msg, style: "red" });
                setValErrors(Fetching.processErrors(data.detail));
                return;
            }

            var message = (isClientEdit) ? "The client has been updated" : "A new client has been created" ;
            setError({ msg: data.msg, style: "grn" });
            flashActions.set({ msg: message, style: "grn" });
            navigate("/admin/clients");

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error creating the user. Please check your data and try again", style: "red" });
        });

    }

    // Load up existing user details
    const loadClient = () => {
        // Check whether there is an edit param set up
        if(typeof urlPath == "undefined" || urlPath.clientRef === 'create'){
            return;
        }

        setLoading(true);

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);
        
        fetch(process.env.REACT_APP_API_BASE + '/clients/' + urlPath.clientRef, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if(data.rslt === 'success'){
                setDetails(data.detail)
                setLoading(false);
            }
           

        })
        .catch(function (error) {
            flashActions.set({ msg: "Sorry but we could not find the user you are looking for. Please try again.", style: "red" });
            navigate('/admin/clients');
        });        
    }

    const checkUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);
        if (!authInfo.permissions.Clients){
            navigate(redirectUrl)
            return
        } else if (urlPath.clientRef !== "create") {
            loadClient(urlPath.clientRef);
        }
    }

    /* eslint-disable */
    useEffect(() => {
        checkUserPermissions()
    },[])
    /* eslint-enable */

    return (
        <CreateClient
            error={ error }
            valErrors={ valErrors }
            handleUpdate={handleUpdate}
            saveClient={saveClient}
            isClientEdit={isClientEdit}
            btnTxt={btnTxt}
            details={details}
            addDomain={addDomain}
            removeDomain={removeDomain}
        />
    );
}

export default CreateClientContainer;
