// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';
import ClientList from '../../../components/admin/clients';


const ClientListContainer = () => {

    // State management
    const [filters, setFilters] = useState({ name: "", status: "" }); // eslint-disable-line
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [rdr, setRdr] = useState(false);
    const [lnk, setLnk] = useState(false);
    const [complete, setComplete] = useState(false);
    const [page, setPage] = useState(1);
    const [error, setError] = useState(false);
    const [clients, setClients] = useState([]);
    const [lb, setLb] = useState(false);
    const [flashState, flashActions] = useFlash();

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line
    const navigate = useNavigate();

    let loginUrl = "/u/login";
    const redirectUrl = "/admin/reports"

    // Handle the update of the filters
    const updateFilters = (e) => {
        let newFilters = {...filters};
        newFilters[e.target.name] = e.target.value;
        setFilters(newFilters);
    }

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadClients = (e, reset) => {
        if (typeof e != "undefined" && e) e.preventDefault();
        if (typeof reset == "undefined") reset = false;

        // If it is complete then 
        if (complete && !reset) return;

        setLoading(true);

        // If it is a reset then set the page to page 1
        let usePage = page;
        if (reset) {
            setPage(1);
            usePage = 1;
            setComplete(false);
        }

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/clients?page=' + usePage, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(filters)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false)
            if (reset) {
                setClients(data.detail)
            } else {
                let newClients = [...clients, ...data.detail];
                setClients(newClients)
            }
            setPage(page + 1);
            
            if (data.detail < 25) {
                setComplete(false, true);
            }

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
                navigate(loginUrl);
            }
            setRdr(loginUrl);
        });
    }

    const checkUserPermissions = () =>{
        
        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);
        if(!authInfo.permissions.Clients){
            setRdr(redirectUrl)
            return
        } else {
            loadClients(false, true);
        }
    }

    // Detect scrolls to the bottom of the page
    /* eslint-disable */
    useEffect(() => {
        window.onscroll = function () {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 190) && loading === false && complete === false) {
                loadClients();
            }
        }
    }, []);
    /* eslint-enable */

    const deleteClient = ( clientId,e ) => {

        e.preventDefault();
        setError(false);

        let doDelete = window.confirm('Do you wish to delete this client?  This cannot be undone');
        if(!doDelete){
            return false;
        }
        
        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        var url =   '/clients/' + clientId;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'delete',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            if (data.rslt === 'success') {
                setError({ msg: 'The client has been deleted', style: "grn" });
                loadClients(false,true);
                return;
            }

            setError({ msg: data.msg, style: "red" });
            console.log(data);

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the client", style: "red" });
        });

    }

    // Handle the display of the lightbox for the client link 
    const displayLink = (e, clientRef) => {

        e.preventDefault();

        // Load the lightbox and clear previously displayed links
        setLnk(false);
        setLb(true);

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Get / generate a link for this client
        fetch(process.env.REACT_APP_API_BASE + "/clients/links/" + clientRef, {
            method: 'get',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            
            if (data.rslt === 'success') {
                setLnk(data.detail);
                return;
            }

            setError({ msg: data.msg, style: "red" });
            setLb(false);

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the client", style: "red" });
        });

    }

    /* eslint-disable */
    useEffect(() => {
        checkUserPermissions()
    },[])
    /* eslint-enable */

    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);

    return (
        <ClientList
            error={ error }
            loading={ loading }
            rdr={ rdr }
            clients={clients}
            deleteClient={deleteClient}
            loadClients={loadClients}
            updateFilters={updateFilters}
            lb={lb}
            setLb={setLb}
            displayLink={displayLink}
            lnk={lnk}
        />
    );
}

export default ClientListContainer;
