// Core react and plugins
import React from 'react';

// const scoreNames = {
//     0: "Non-existent",
//     1: "Very weak",
//     2: "Weak",
//     3: "Weak",
//     4: "Reasonable",
//     5: "Strong",
//     6: "Very strong"
// }

// Component class itself
const PwMeter = (props) => {

    return (

        <div className={(props.strength.hasEntry && props.strength.score < 6) ? "strength-meter display" : "strength-meter"}>
            <p>As a minimum we recommend setting a password that fits these criteria:</p>
            <ul>
                <li className={(props.strength.length) ? "ticked" : ""}>Password should be at least 8 characters long</li>
                <li className={(props.strength.lc) ? "ticked" : ""}>Password should contain at least one lowercase letter</li>
                <li className={(props.strength.uc) ? "ticked" : ""}>Password should be at least one uppercase letter</li>
                <li className={(props.strength.number) ? "ticked" : ""}>Password should be at least one number</li>
                <li className={(props.strength.char) ? "ticked" : ""}>Password should be at least one other character</li>
                <li className={(props.strength.noDict) ? "ticked" : ""}>Password should not contain commonly used passwords like &quot;password&quot;</li>
            </ul>
        </div>

    );

}

export default PwMeter;