// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import CoachesList from '../../../components/admin/coaches';
import useFlash from '../../../store/flash';

const CoachesListContainer = () => {

    // State management
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [error, setError] = useState(false);
    const [coaches, setCoaches] = useState([]);
    const [flashState, flashActions] = useFlash();

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line
    const navigate = useNavigate();

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadCoaches = (e, reset) => {

        setLoading(true);

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/coaches', {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false)
            if (data.rslt === "success") {
                setCoaches(data.detail)
            }

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
                navigate("/u/login");
            }
            setError({ msg: "Sorry, but we could not load the reports.", style: "red"})
        });
    }

    // Handle the deletion of the coach 
    const handleDelete = (e, coachId) => {
        e.preventDefault();

        if (!window.confirm("Are you sure you want to delete this coach?")) {
            return;
        }

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Dp the deletion
        fetch(process.env.REACT_APP_API_BASE + '/coaches/' + coachId, {
            method: 'delete',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                loadCoaches();
                setError({ msg: "The coach has been successfully deleted.", style: "green"})
                return;
            }
            setError({ msg: data.msg, style: "red"});

        })
        .catch(function (error) {
            setError({ msg: "Sorry, but we could not delete the coach.", style: "red"})
        });
    }

    /* eslint-disable */
    useEffect(() => {
        loadCoaches();
    },[])
    /* eslint-enable */

    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);

    return (
        <CoachesList 
            loading={loading}
            error={error}
            coaches={coaches}
            handleDelete={handleDelete}
        />
    );
}

export default CoachesListContainer;
