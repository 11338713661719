// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from './../../../utilities/fetching';
import CryptoJS from 'crypto-js';

// State management
import useAuth from './../../../store/auth';
import useFlash from './../../../store/flash';
import { useCookies } from 'react-cookie';

// View components
import Login from './../../../components/users/auth/login';

const LoginContainer = () => {

    // State management
    let encSecret = process.env.REACT_APP_ENC_SECRET;
    const [error, setError] = useState(false);
    const [btnTxt, setBtnTxt] = useState("Sign in");
    const [authInfo, setAuthInfo] = useState({ email: false, password: false });
    const [authState, authActions] = useAuth(); // eslint-disable-line
    const [flashState, flashActions] = useFlash();
    const [authCookie, setCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);// eslint-disable-line

    const navigate = useNavigate();
    
    // Update the auth info local state (based upon entered info)
    const handleAuthInfo = ( fieldname, e ) => {
        var newInfo = { ...authInfo };
        newInfo[fieldname] = e.target.value;
        setAuthInfo(newInfo);
    }

    // Login function
    // Submit the entered login info to the login endpoint
    // Upon success redirect (either to the MFA setup or MFA verification)
    const loginFunc = (e) => {

        e.preventDefault();
        setBtnTxt("Signing in...");

        var data = {
            email:authInfo.email,
            password:authInfo.password
        }

        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/users/login', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === 'success' && typeof data.token != "undefined" && typeof data.key != "undefined") {

                const dataToStore = {
                    apiKey:data.key,
                    apiToken:data.token,
                    key: data.key,
                    token: data.token,
                    client:data.usrDetail.Client,
                    email:data.usrDetail.Email,
                    firstName:data.usrDetail.FirstName,
                    lastName:data.usrDetail.LastName,
                    manager:data.usrDetail.Manager,
                    mobileNo:data.usrDetail.MobileNo,
                    permissions:data.usrDetail.Permissions,
                    ref:data.usrDetail.Ref
                }

                // Upon successful login, store the session info to state (and into an encrypted cookie for more permanent storage)
                authActions.storeSession(dataToStore);

                var encData = { a: CryptoJS.AES.encrypt(JSON.stringify(dataToStore), encSecret).toString() };
                setCookie(process.env.REACT_APP_AUTH_COOKIE, encData, { path: '/', secure: true, sameSite: true });

                if (data.usrDetail.Permissions.IsAdmin) {
                    navigate('/admin/reports')
                    return;
                } 
                navigate('/q/questionnaire')

            } else {

                // Upon failure - display an error
                if (typeof data.error != 'undefined') {
                    setError({ msg: data.error, style: "red" });
                } else {
                    setError({ msg: "Sorry but there was a problem signing you in. Please check your details and try again.", style: "red" });
                }

                setBtnTxt("Sign in");
            }
            

        })
        .catch(function (error) {
            setBtnTxt("Sign in");
            setError({ msg: "Sorry but there was a problem signing you in. Please check your details and try again.", style: "red" });
        });

    }

  

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        if ( flashState.msg ) {
            setError(flashState);
            flashActions.clear();
        }
    }, []);
    /* eslint-enable */

    return (
        <Login
            loginFunc={ loginFunc }
            handleAuthInfo={ handleAuthInfo }
            error={ error }
            btnTxt={ btnTxt }
        />
    );
}

export default LoginContainer;
