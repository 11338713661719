// Core react and plugins
import React, {useState} from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import HeaderBg from './../../assets/img/ch/landing-header.png';

import LineGraph from './../../assets/img/ch/line-graph.svg';
import Speedo from './../../assets/img/ch/speedo.svg';
import Blobs from './../../assets/img/ch/blobs.svg';
import Girl from './../../assets/img/ch/right-girl.png'

import StepOne from './../../assets/img/ch/animate/step-1-icon.gif';
import StepTwo from './../../assets/img/ch/animate/step-2-icon.gif';
import StepThree from './../../assets/img/ch/animate/step-3-icon.gif';

const Landing = (props) => {

    const [headerLoaded, setHeaderLoaded] = useState(false);

    return (
        <div className="landing">
            <Helmet>
                <title>The Coaching Hub :: Created by Abstract UK</title>
            </Helmet>
            
            <section className="head-banner">
                <div className="content">
                    <div className="width-5">
                        <h1>Welcome to The Coaching Hub</h1>
                        
                        <p>Congratulations on starting your Coaching Journey!</p>
                        <p>To find the perfect coach for you, our ABSTRACT experts just need you to answer a few questions, and we&rsquo;ll take care of the rest.</p>
                        <p>Click the button below to complete our short assessment.</p>

                        <Link className="btn primary" to="/q/get-started">Start Your Coaching Journey</Link>
                    </div>
                </div>

                <img src={HeaderBg} alt="Get started with The Coaching Hub background" className={ headerLoaded ? 'bg-img' : "bg-img load-it" } onLoad={ () => setHeaderLoaded(true) } />
            </section>
            
            <section className="expectations">
                <div className="content">
                    <div className="img-rght med">
                        <h2>What Can I Expect?</h2>
                        
                        <div className="thirds">

                            <div className="third blue">
                                <img src={LineGraph} alt="Line Graph icon" />
                                <h5>A coaching process comprising of six 1 hour sessions.</h5>
                            </div>

                            <div className="third blue">
                                <img src={Speedo} alt="Speedometer icon" />
                                <h5>A challenging and supportive experience that pushes you to take action.</h5>
                            </div>

                            <div className="third blue">
                                <img src={Blobs} alt="Partner icon" />
                                <h5>Partner with a highly qualified, expert personal coach.</h5>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="how-it-works">
                <div className="right-girl">
                    <img src={Girl} alt="Lady on a laptop" />
                </div>
                <div className="content">

                    <div className="width-12 h-wrap">
                        <h2>How It Works</h2>
                    </div>

                    <div className="thirds">

                        <div className="third">
                            <img src={StepOne} alt="Assessment icon" />
                            <h5>Step 1</h5>
                            <p>Complete your assessment.</p>
                        </div>

                        <div className="third">
                            <img src={StepTwo} alt="Experts icon" />
                            <h5>Step 2</h5>
                            <p>ABSTRACT experts assess your submission and match you with your ideal coach.</p>
                        </div>

                        <div className="third">
                            <img src={StepThree} alt="Introductions icon" />
                            <h5>Step 3</h5>
                            <p>You&rsquo;ll receive introductions to your coach, and start your coaching journey.</p>
                        </div>

                    </div>

                    <Link className="btn primary centred" to="/q/get-started">Start Your Coaching Journey</Link>

                </div>
            </section>
        </div>
    );
}

export default Landing;
