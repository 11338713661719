import React, { Fragment } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import FooterContainer from '../containers/common/footer-container';
import HeaderContainer from '../containers/common/header-container';
import Errors from './common/errors';
import UsrSettingsContainer from '../containers/users/auth/usr-settings-container';
import UserListContainer from '../containers/users/index-container';
import ClientListContainer from '../containers/admin/clients/index-container';
import CreateClientContainer from '../containers/admin/clients/create-container';
import ReportsListContainer from '../containers/admin/reports/index-container';
import ReportViewContainer from '../containers/admin/reports/view-container';
import CoachesListContainer from '../containers/admin/coaches/index-container';
import CoachCreateContainer from '../containers/admin/coaches/create-container';
import CreateContainer from '../containers/users/create-container';

function AuthRouting(props) {

    const params = useParams();

    const widePages = ["config", "packages", "packages/cancel"];

    return (
            <Fragment>
                <HeaderContainer isAdmin={ true } isLoggedIn={ props.isLoggedIn } />
                
                <div className={ widePages.indexOf(params["*"]) > -1 ? "main-content wide" : "main-content"}>
                    <Routes>
                        <Route exact path='/reports' element={<ReportsListContainer />} />
                        <Route path='/reports/:repRef' element={<ReportViewContainer />} />
                        <Route exact path='/clients' element={<ClientListContainer />} />
                        <Route path='/clients/:clientRef' element={<CreateClientContainer />} />
                        <Route path='/coach/:coachId' element={<CoachCreateContainer />} />
                        <Route exact path='/coaches' element={<CoachesListContainer />} />
                        <Route exact path='/settings' element={<UsrSettingsContainer />} />
                        <Route path='/users/:userRef' element={<CreateContainer />} />
                        <Route exact path='/users' element={<UserListContainer />} />
                        <Route path='*' element={<Errors/>} /> 
                    </Routes>
                </div>

                <FooterContainer isAdmin={ true } />
            </Fragment>
        );

    }

export default AuthRouting;
