// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../utilities/fetching';
import Encryption from '../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../store/flash';

import UserList from '../../components/users';

const UserListContainer = () => {

    // State management
    const [rdr, setRdr] = useState(false);
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [error, setError] = useState(false);
    const [lbError, setLbError] = useState(false);
    const [users, setUsers] = useState([]);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [userReports,setUserReports] = useState([]) // eslint-disable-line

    const [searchEntry,setSearchEntry] = useState('');
    const [client,setClient] = useState(0);
    const [clients, setClients] = useState([]);
    const [userPermissions,setUserPermissions] = useState({})
    const [lightBoxOpen,toggleLightBoxOpen] = useState(false)
    const [lightboxView,setLightBoxView] = useState(false)
    const [passwordResetUrl,setPasswordResetUrl] = useState(false)
    // const [passwordResetlightBoxOpen,togglePasswordResetLightBoxOpen] = useState(false)
    const navigate = useNavigate();

    let loginUrl = "/u/login";
    let redirectUrl = '/admin/reports'

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadUsers = () => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        let data = {
            clientId: parseInt(client),
            search: searchEntry
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/users/list', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            // Set the state for the users

                setLoading(false);

                if (data === null) {
                    setUsers([]);
                } else {

                    for(let i = 0; i<data.length;i++){
        
                        if(data[i].Ref === authInfo.ref){
                            data[i].hideLink = true
                        } else {
                            data[i].hideLink = false
                        }
                    }

                    setUsers(data);

                }

            // setError({ msg: "Sorry but there was an error loading the users.", style: "red" });
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
                navigate(loginUrl);
            }
        });
    }

    let loadClients = () => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/clients/list', {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setClients(data.detail);
            }
            
        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the page you are looking for. Please sign in and try again.", style: "red" });
            }
            setRdr(loginUrl);
        });
    }

    const deleteUser = ( userEmail,e ) => {

        e.preventDefault();
        setError(false);

        let doDelete = window.confirm('Do you wish to delete this user?  This cannot be undone');

        if(!doDelete){
            return false;
        }
        
        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        var url =   '/users/delete'

        var data ={
            usrEmail:userEmail
        }
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'POST',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body:JSON.stringify(data)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            

            setError({ msg: 'The user has been deleted', style: "grn" });
            loadUsers()
            // flashActions.set({ msg: message, style: "grn" });
            // setRdr("/admin/manage/clients");

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the user", style: "red" });
        });

    }

    // Reset a user so they can take the questionnaire again
    const resetUsr = (e, uid) => {
        
        e.preventDefault();

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/reports/refresh/" + uid, {
            method: 'get',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            setLbError({ msg: "The user has been reset so they can take the self-assessment again.", class: "flash-msg grn" });

        })
        .catch(function (error) {
            setLbError({ msg: "Sorry but there was an error deleting the user", class: "flash-msg red" });
        });
    }

    // Reset a user so they can take the questionnaire again
    const resetUsrPw = (uid,e) => {

        e.preventDefault()

        let passwordResetConfirmed = window.confirm("This will reset this user's password.  Do you wish to continue?")

        if(!passwordResetConfirmed){
            return
        }

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/users/manager-password/" + uid, {
            method: 'get',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setLightBoxView('resetPassword')
                setPasswordResetUrl(data.detail);
                toggleLightBoxOpen(true)
                // setLbError({ msg: "The user has been reset so they can answer the questionnaire again.", class: "flash-msg grn" });
                return;
            }

            setLbError({ msg: "Sorry but there was an error resetting the user.", class: "flash-msg" });

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error deleting the user", style: "red" });
        });

    }

    // useEffect to load up the auth check when the component mounts
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);

    const checkUserPermissions = () =>{
        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);
        if(!authInfo.permissions.Users){
            setRdr(redirectUrl)
            return
        } else {
            setUserPermissions(authInfo.permissions)
            loadUsers()
        }
    }

    /* eslint-disable */
    useEffect(() => {
        document.title = 'Manage users :: The Coaching Hub';
        checkUserPermissions()
        loadClients();
    },[])
    /* eslint-enable */

    const closeLightbox = (e) => {
        e.preventDefault();
        setLightBoxView(false)
        toggleLightBoxOpen(false)
        setLbError(false);
    }

    return (
        <UserList
            error={ error }
            rdr={ rdr }
            users={ users }
            deleteUser={deleteUser}
            setClient={setClient}
            setSearchEntry={ setSearchEntry }
            loadUsers={ loadUsers }
            clients={ clients }
            lightBoxOpen={lightBoxOpen}
            toggleLightBoxOpen={toggleLightBoxOpen}
            userReports={userReports}
            resetUsr={ resetUsr }
            userPermissions={userPermissions}
            lbError={ lbError }
            closeLightbox={closeLightbox}
            lightboxView={lightboxView}
            passwordResetUrl={passwordResetUrl}
            resetUsrPw={resetUsrPw}
        />
    );
}

export default UserListContainer;
