import React from 'react';
import jsPDF from 'jspdf'
import moment from "moment";

import './../../../assets/fonts/FiraSans-Regular-normal.js';
import './../../../assets/fonts/FiraSans-Medium-normal.js';
import './../../../assets/fonts/FiraSans-Thin-normal.js';

const Download = (props) => {

    const headerFontSize = 16;
    const subheaderFontSize = 14;
    const color = '#012265';
    const colorFaded = '#4c6698';
    const subheadColor = '#57AEE2';
    const bodyFontSize = 8.5;
    const bodyHeadSize = 9.5;
    const yellow = '#FDC743';

    const generatePDF = () => {

        props.setBtnTxt("Downloading...");

        var doc = new jsPDF({
            orientation: "p",
            format: "a4",
            unit: "mm",
            putOnlyUsedFonts: true
        });

        // Add the content to the doc
        doc = addTemplate(doc);

        // Add in the questions and answers
        var availableHeight = 205;
        var usedHeight = 0;
        var itemHeight = 0;
        var titleHeight = 0;
        var startPosn = 0;
        for (var i in props.report.answers) {

            var ans = props.report.answers[i];

            // Calcuate the potential height of the question and answer
            var answer = ans.answer.replace(/&#39;/g, "'");
            doc.setFontSize(bodyHeadSize)
            doc.setTextColor(color)
            doc.setFont('FiraSans-Medium', 'normal');
            titleHeight = doc.getTextDimensions(ans.question.question, { align: "left", maxWidth: 190 }).h;
            itemHeight += titleHeight;

            doc.setFont('FiraSans-Thin', 'normal');
            doc.setFontSize(bodyFontSize)
            itemHeight += doc.getTextDimensions(answer, { align: "left", maxWidth: 190 }).h;

            // If the item height is greater than the available height, then add a new page
            if ((usedHeight + itemHeight) > availableHeight + 22) {
                doc.addPage();
                doc = addTemplate(doc);
                startPosn = 52;
            } else {
                startPosn = (usedHeight === 0) ? 52 : usedHeight + 15;
            }
            usedHeight = startPosn + itemHeight;
            itemHeight = 0;

            // Append the content to the page
            doc.setFontSize(bodyHeadSize)
            doc.setTextColor(color)
            doc.setFont('FiraSans-Medium', 'normal');
            doc.text(10, startPosn, ans.question.question, { align: "left", maxWidth: 190 })

            doc.setFont('FiraSans-Thin', 'normal');
            doc.setFontSize(bodyFontSize)
            doc.setTextColor(color)
            doc.text(10, startPosn + titleHeight + 2, answer, { align: "left", maxWidth: 190 })

        }

        // Run the export
        var repDate = moment(props.report.createdAt).format("YYYYMMDD");
        var initials = getInitials(props.report.user.firstName, props.report.user.lastName);
        doc.save('tch-report-' + initials + "-" + repDate + '.pdf')

        setTimeout(() => {
            props.setBtnTxt("Download the report");
        }, 2500);

    }

    // Get the initials of the user
    const getInitials = (firstName, lastName) => {

        var fn = firstName.split("-");
        var ln = lastName.split("-");

        var initials = "";
        for (var i in fn) { 
            if (fn[i].length > 0) {
                initials += fn[i].charAt(0).toLowerCase();
            }
        }
        for (var j in ln) {
            if (ln[j].length > 0) {
                initials += ln[j].charAt(0).toLowerCase();
            }
        }

        return initials;

    }

    // Add template 
    const addTemplate = (doc) => {
        // Page header 
        doc.addImage("/pdf-img/coaching-hub-logo.png", "PNG", 10, 10, 65, 24);

        doc.setFont('FiraSans-Medium', 'normal');
        doc.setFontSize(headerFontSize)
        doc.setTextColor(color)
        doc.text(200, 22, "The Coaching Hub Profile", { align: "right" })

        var name = props.report.user.firstName + " " + props.report.user.lastName;
        name += " - " + moment(props.report.createdAt).format("DD/MM/YYYY");
        doc.setFont('FiraSans-Regular', 'normal');
        doc.setFontSize(subheaderFontSize)
        doc.text(200, 30, name, { align: "right" })

        doc.setFillColor(subheadColor)
        doc.roundedRect(10, 42, 190, 1, 0.5, 0.5, 'F');

        // Footer 
        doc.setFillColor(yellow)
        doc.circle(0, 312, 55, 'F');
        doc.circle(210, 418, 150, 'F');

        doc.setFont('FiraSans-Thin', 'normal');
        doc.setFontSize(5)
        doc.setTextColor(colorFaded)
        doc.text(105, 293, "© 2023 AbstractUK", { align: "center" })

        doc.addImage("/pdf-img/man-cheers.png", "PNG", 22, 262, 55, 46);

        return doc;
    }

    if (!props.report) {
        return "";
    }

    return (
        <div>
            <button onClick={generatePDF} type="primary" className="btn primary sml right">{ props.btnTxt }</button>
        </div>
    );
}
export default Download;