// Core react and plugins
import React from 'react';
import { Helmet } from "react-helmet";

import { Link } from 'react-router-dom';
import TextInput from '../../common/forms/text-input';
import CheerMan from './../../../assets/img/ch/man-cheers.png';

// Components
import Error from './../../common/forms/error';
import SetupHeader from '../../common/setup-header';

// Component class itself
function ForgottenPw(props) {

    return (
        <div className="questionnaire short">
            <Helmet>
                <title>Forgotten password :: The Coaching Hub</title>
            </Helmet>

            <SetupHeader title="Forgotten Password" subTitle={false} />

            <div className="q-content">

                <div className="q-steps">
                    <Error error={ props.error } />

                    <form>
                        <TextInput label={'Email Address'} id={'email'} handleChange={ props.handleUpdate} />

                        <button className="btn primary left" onClick={ (e) => props.submitFunc(e) }>{ props.btnTxt }</button>
                        <p className="sml right"><Link to="/u/login">Return to login</Link></p>
                    </form>

                </div>
            </div>

            <div className="side-img lft">
                <img src={CheerMan} alt="A man celerating" />
            </div>

            <div className="q-side rght"></div>
        </div>
    );

}

export default ForgottenPw;