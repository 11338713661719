import React, {Fragment} from 'react';

function SetupHeader(props){

    return (
        <div className="intro">
            <div className="content">
                <div className="width-6 centred">
                    <h1>{ props.title }</h1>
                    { props.subTitle &&
                        <Fragment>
                            <p dangerouslySetInnerHTML={{ __html: props.subTitle}}></p>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default SetupHeader