// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import ReportView from '../../../components/admin/reports/view';

const ReportViewContainer = () => {

    // State management
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [report, setReport] = useState(false);
    const [coaches, setCoaches] = useState([]); // eslint-disable-line
    const [coach, setCoach] = useState(false); // eslint-disable-line

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    const params = useParams();
    const navigate = useNavigate();

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadReport = (e, reset) => {

        setLoading(true);

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Get the report ID from the URL
        let reportId = params.repRef;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/reports/' + reportId, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setReport(data.detail);

                // If this report has a coach assigned, then set the coach state
                if (data.detail.assignedCoachId > 0) {
                    setCoach(data.detail.assignedCoachId);
                }

                setLoading(false)
                return;
            }

            setLoading(false)
            setError({ msg: data.msg, style: "red"});

        })
        .catch(function (error) {
            if (error === "auth") {
                navigate("/u/login");
            }
            setError({ msg: "Sorry, but we could not load the reports.", style: "red"})
        });
    }

    // Load coaches 
    const loadCoaches = () => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/coaches', {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false)
            if (data.rslt === "success") {
                setCoaches(data.detail);
                return;
            }

            setError({ msg: data.msg, style: "red"});

        })
        .catch(function (error) {
            setError({ msg: "Sorry, but we could not load the coaches.", style: "red"})
        });

    }

    // Handle the assignment of the coach 
    const saveCoach = (e) => {
        e.preventDefault();

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);
        let reportId = params.repRef;

        fetch(process.env.REACT_APP_API_BASE + '/reports/' + reportId + "/coach/" + coach, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false)
            scrollToTop();
            if (data.rslt === "success") {
                setError({ msg: "The coach has been assigned successfully.", style: "green"});
                return;
            }

            setError({ msg: data.msg, style: "red"});

        })
        .catch(function (error) {
            setError({ msg: "Sorry, but there was an error assigning the coach.", style: "red"})
        });

    }

    // Scroll the window to the top 
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    /* eslint-disable */
    useEffect(() => {
        loadReport();
        loadCoaches();
    },[])
    /* eslint-enable */

    return (
        <ReportView 
            loading={loading}
            error={error}
            setError={setError}
            report={report}
            coaches={coaches}
            coach={coach}
            updateCoach={setCoach}
            saveCoach={ saveCoach }
        />
    );
}

export default ReportViewContainer;
