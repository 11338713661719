import React, {useState} from 'react';
import { Routes, Route } from 'react-router-dom'

// View components
import DetailsContainer from './../../containers/questionnaire/step-details-container';
import SetupHeader from './../common/setup-header';

import CheerMan from './../../assets/img/ch/man-cheers.png';
import LaptopLady from './../../assets/img/ch/right-girl.png';
import QuestionsContainer from '../../containers/questionnaire/step-questions-container';
import ProgressBar from './progress-bar';
import SubmitContainer from '../../containers/questionnaire/step-submit-container';
import ThanksContainer from '../../containers/questionnaire/step-thanks-container';

const Questionnaire = (props) => {

    const [doSubmit, setDoSubmit] = useState(false);

    const titleContent = {
        0: {
            title: "Get Started",
            subTitle: "Sign up to complete the questionnaire and create your profile.",
            class: ""
        },
        1: {
            title: "Get Started",
            subTitle: "Sign up to complete the questionnaire and create your profile.",
            class: ""
        },
        2: {
            title: "Create Your Profile",
            subTitle: "Complete the questions below to create a profile of what you are looking for in your coaching.",
            class: ""
        },
        3: {
            title: "Profile Complete",
            subTitle: "Congratulations you have completed the questionnaire. <br />To finish the questionnaire and find your ideal coach, please check the information you have provided below and select submit.",
            class: " big"
        },
        4: {
            title: "Thank You",
            subTitle: "Thank you for submitting your profile to our team. <br />We will get back to you shortly with details of the perfect coach for you and your requirements.",
            class: ""
        }
    };

    return (
        <div className={props.step % 2 === 0 ? "questionnaire blue" + titleContent[props.step].class : "questionnaire" + titleContent[props.step].class}>

            <SetupHeader title={titleContent[props.step].title} subTitle={titleContent[props.step].subTitle} />

            { props.step === 2 &&
                <ProgressBar questions={ props.questions } current={ props.current } />
            }

            {props.step === 3 &&
                <button className="btn primary centred" onClick={() => setDoSubmit(true)}>Submit profile</button>
            }

            <div className={"q-content" + titleContent[props.step].class}>
                <div className={props.displayState === "" ? "q-steps" : "q-steps " + props.displayState }>
                    <Routes>
                        <Route path='get-started' element={<DetailsContainer {...props} />} />
                        <Route path='questionnaire' element={<QuestionsContainer {...props} />} />
                        <Route path='submit' element={<SubmitContainer doSubmit={doSubmit} {...props} />} />
                        <Route path='thanks' element={<ThanksContainer {...props} />} />
                        <Route index element={<DetailsContainer {...props} />} />
                    </Routes>
                </div>
            </div>

            <div className="q-side lft"></div>
            <div className="side-img lft">
                <img src={CheerMan} alt="A man celerating" />
            </div>

            <div className="q-side rght"></div>
            <div className="side-img rght">
                <img src={LaptopLady} alt="A happy lady using a laptop" />
            </div>
        </div>
    );
}

export default Questionnaire;
