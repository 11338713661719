import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Error from '../../common/forms/error';
import Loader from '../../common/loader';

function ClientList(props){

    return (
        <div className="content admin-content">
            <div className="width-12">
                <Link to='/admin/clients/create' className='btn sml right'>Create</Link>
                <h1>Manage clients</h1>
            </div>
                
            <section className="width-9">
                <Error error={ props.error } />
                <table>
                    <thead>
                        <tr>
                            <th>Client name</th>
                            <th>Licences remaining</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        { props.loading && props.clients.length === 0 ? (
                            <tr>
                                <td colSpan="2"><Loader /></td>
                            </tr>
                        ) : (
                            <Fragment>
                                { props.clients.length === 0 ? (
                                    <tr>
                                        <td colSpan="2" className="centre"><em>No clients found</em></td>
                                    </tr>
                                ) : (
                                    <Fragment>
                                        { typeof props.clients != "undefined" && props.clients.map( (client,i) => {
                                            
                                            return (
                                                <tr key={'tr-'+i}>
                                                    <td>{client.clientName}</td>
                                                    <td>{ client.licences - client.licencesUsed }</td>
                                                    <td>
                                                        <Link to={'/admin/clients/' + client.ref} className="btn">Edit</Link>
                                                        <button className="btn" onClick={ (e) => props.displayLink(e, client.ref) }>Link</button>
                                                        <Link to='#' onClick={(e) => props.deleteClient(client.ref,e)} className="btn secondary">Delete</Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                        
                    </tbody>
                </table>

            </section>

            <div className="width-3 filter">
                <h2>Filter clients</h2>

                <div className="input text">
                    <label htmlFor="name">Client name</label>
                    <input type="text" name="name" id="name" onChange={ (e) => props.updateFilters(e) } />
                </div>

                <div className="input select">
                    <label htmlFor="status">Status</label>
                    <select name="status" id="status" onChange={(e) => props.updateFilters(e)}>
                        <option value="">Select a status</option>
                        <option value="active">Active</option>
                        <option value="archived">Archived</option>
                    </select>
                </div>

                <button className="btn primary sml" onClick={ (e) => props.loadClients(e, true) }>Filter</button>
            </div>
  
            { props.lb &&
                <div className="cover">
                    <button className="close-btn" onClick={ () => props.setLb(false) }>Close</button>
                    <div className="lb">
                        <h3>Access link</h3>

                        { props.lnk ? (
                            <Fragment>
                                <p>Users from this client can access the questionnaire using the link below:</p>
                                <div className="highlight">
                                    { props.lnk.map( (l,i) => 
                                        <p key={ "lnk-" + i }>{ l }</p>    
                                    )}
                                </div>
                            </Fragment>
                        ) : (
                            <Loader />
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default ClientList