import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Encryption from '../../utilities/encryption';
import Fetching from '../../utilities/fetching';

// View components
import Questionnaire from '../../components/questionnaire/questionnaire';

const QuestionnaireContainer = (props) => {

    var navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [isRestart, setIsRestart] = useState(false);
    const [displayState, setDisplayState] = useState("appearing");
    const [questions, setQuestions] = useState([]);
    const [current, setCurrent] = useState(0);
    // const [valErrors, setValErrors] = useState(false);
    const [error, setError] = useState(false); // eslint-disable-line

    // Cookies
    const [accCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // get the current route using react router
    const { pathname } = useLocation();

    // Set up the options for the different steps
    const stepOpts = {
        1: "get-started",
        2: "questionnaire",
        3: "submit",
        4: "thanks"
    }

    // Check whether we are already in the process of creating an account
    const inProgress = (cookie) => {
        if (typeof accCookie[process.env.REACT_APP_AUTH_COOKIE] === "undefined") {
            return false;
        }
        if (typeof accCookie[process.env.REACT_APP_AUTH_COOKIE].a === "undefined") {
            return false;
        }
        return true;
    }

    // Load up the screen 
    const loadScreen = () => {

        // Check the point in the process based upon the path 
        // Explode the pathname around slashes
        var path = pathname.split("/");
        // Get the last non-empty item in the array 
        var last = path[path.length - 1];
        if (last === "restart") setIsRestart(true);
        if (last === "q" || last === "" || last === "restart") last = "get-started";
        // Find the key for this in the stepOpts object
        var stp = parseInt(Object.keys(stepOpts).find(key => stepOpts[key] === last));

        // Check whether we are already in the process of creating an account
        if (inProgress()) {
            // TODO: Check whether the current step is the current page 
            // if ((parseInt(accCookie[process.env.REACT_APP_AUTH_COOKIE].stc) + 1) !== stp) {
            //     // If so then navigate to the appropriate point
            //     navigate("/q/" + stepOpts[accCookie[process.env.REACT_APP_AUTH_COOKIE].stc + 1]);
            //     return;
            // }
        } else {
            // If not then check that we are on the first step
            if (last !== "get-started") {
                navigate("/q/get-started");
                return;
            }
        }

        // Set the display state to appearing
        setDisplayState("appearing");

        // Now wait for the animation to finish
        setTimeout(() => {

            // Set the current step based upon the pathname 
            setStep(stp);
            setDisplayState("");

        }, 250);

    }

    // Handle the switch between steps
    const updateStep = (direction, isInternal = false) => {
        // Hide the old step
        var newState = (direction === "nxt") ? "hiding" : "appearing";
        setDisplayState(newState);

        // Wait and then update the step
        setTimeout(() => {

            if (!isInternal) {

                // Update the step
                var newStep = (direction === "nxt") ? step + 1 : step - 1;
                setStep(newStep);

            } else {

                // Update the question 
                var enterState = (direction !== "nxt") ? "hiding" : "appearing";
                setDisplayState(enterState);

                setTimeout(() => {
                    var newQuestion = (direction === "nxt") ? current + 1 : current - 1;
                    if ( typeof questions[newQuestion] !== "undefined" ) {
                        setCurrent(newQuestion);
                        setDisplayState("");
                    }
                }, 100);
                return;

            }

            // If the new step exists then navigate to it
            if (stepOpts[newStep]) {
                navigate('/q/' + stepOpts[newStep]);
                return;
            }

            // If not then navigate to the dashboard
            navigate("/q/get-started");

        }, 250);
    }

    // Get questions 
    const getQuestions = ( ) => {

        // Clear out any errors
        setError(false);

        // Get the auth cookie 
        let tokenInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/questionnaire", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                "api-token": tokenInfo.token,
                "api-key": tokenInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Set the questions
                setQuestions(data.detail);

            } 

        })
        .catch(function (error) {
            console.log(error);
        });

    }

    // TODO: Check whether they have existing reports
    // If they do then forward them to the Thanks screen
    const checkExisting = () => {

        // Get the auth cookie 
        let tokenInfo = Encryption.extractHeaders(accCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/questionnaire/report", {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                "api-token": tokenInfo.token,
                "api-key": tokenInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Check for a status that allows updates
                if (data.detail.status !== "in-progress" && pathname !== "/q/thanks") {
                    // Redirect to the thanks page (if not already there)
                    navigate("/q/thanks");
                }

            } 

        })
        .catch(function (error) {
            console.log(error);
        });

    }


    /* eslint-disable */
    useEffect(() => {
        loadScreen();
        checkExisting();
    }, [])
    useEffect(() => {
        loadScreen();
        if (pathname === "/q/questionnaire") getQuestions();
    }, [pathname])
    /* eslint-enable */

    return (
        <Questionnaire 
            updateStep={updateStep}
            displayState={displayState}
            isRestart={isRestart}
            step={step}
            questions={questions}
            current={current}
            setLoginStatus={props.setLoginStatus}
            regRef={typeof accCookie[process.env.REACT_APP_AUTH_COOKIE] !== "undefined" && typeof accCookie[process.env.REACT_APP_AUTH_COOKIE].cr !== "undefined" ? accCookie[process.env.REACT_APP_AUTH_COOKIE].cr : "" }
        />
    );
}

export default QuestionnaireContainer;
