import React from 'react';
import { Helmet } from "react-helmet";

import ChangeDetailsContainer from '../../../containers/users/auth/change-details-container';
import UpdatePasswordContainer from '../../../containers/users/auth/update-password-container';

function UsrSettings(props){

    return (
        <div className="usr-settings">
            <Helmet>
                <title>My settings :: The Coaching Hub</title>
            </Helmet>

            <ul className="tabs">
                <li className={props.tab === "settings" ? "active" : ""} onClick={() => props.setTab("settings")}>My details</li>
                <li className={props.tab === "password" ? "active" : ""} onClick={() => props.setTab("password")}>Update password</li>
            </ul>

            { props.tab === "settings" ? <ChangeDetailsContainer /> : null }
            { props.tab === "password" ? <UpdatePasswordContainer /> : null }
  
        </div>
    )
}

export default UsrSettings;