// Core react and plugins
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


// Components
import Error from './../../common/forms/error';
import SetupHeader from '../../common/setup-header';
import Loader from '../../common/loader';

// Component class itself
function Verify(props) {

    return (
        <div className="registration">
            <Helmet>
                <title>Verify your email :: The Coaching Hub</title>
            </Helmet>

            <SetupHeader title="Verify account" subTitle={ false } />

            <div className="login-content">

                <form>

                    { props.loading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            { props.outcome ? (
                                <p>Thanks for verifying your email address. You can now sign in and use the The Coaching Hub.</p>
                            ) : (
                                <Fragment>
                                    <p>Sorry but there seems to have been an error verifying your email address. You can still sign in and configure your account, but can&rsquo;t submit the questionnaire yetß.</p>
                                    <p>If you would like some help, feel free to contact our team: <a href="mailto:support@thecoachinghub.net" target="_blank" rel="noreferrer">support@thecoachinghub.net</a></p>
                                </Fragment>
                            )}
                            <Error error={props.error} />
                            <Link to="/" className="btn primary">Sign in</Link>
                        </Fragment>
                    )}

                </form>

            </div>
        </div>

    );

}

export default Verify;