import React from "react";

// View components
import Thanks from "../../components/questionnaire/step-thanks";

const ThanksContainer = (props) => {

    return (
        <Thanks />
    );
}

export default ThanksContainer;
