import React, { useState } from "react";

// Import utilities 
import Fetching from "./../../utilities/fetching";
import { useCookies } from 'react-cookie';

// View components
import Details from "./../../components/questionnaire/step-details";
import Encryption from "../../utilities/encryption";
import CryptoJS from "crypto-js";

const DetailsContainer = (props) => {

    // Track state for this screen
    let encSecret = process.env.REACT_APP_ENC_SECRET;
    const [details, setDetails] = useState({ firstName: "", lastName: "", jobTitle: "", email: "", password: "" });
    const [valErrors, setValErrors] = useState(false);
    const [error, setError] = useState(false);
    const [cookieStatus, setCookieStatus] = useState(false);
    const [strength, setStrength] = useState({
        score: 0,
        lc: false,
        uc: false,
        length: false,
        number: false,
        char: false,
        noDict: false,
        hasEntry: false
    });

    // Cookies
    const [accCookie, setCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // On load - check whether there is a cookie set
    const checkCookie = () => {

        // Check the cookie is valid
        let tokenInfo = Encryption.extractTokens(accCookie);
        if (tokenInfo === false) {
            setCookieStatus('no-cookie');
            return;
        }

        // Otherwise check the token is valid
        fetch(process.env.REACT_APP_API_BASE + "/public/check", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(tokenInfo)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                // Store a cookie with the user reference
                setCookieStatus('ok');
                return;

            } 

            console.log(data.rslt);

            if (data.rslt === 'limit-exceeded') {
                setCookieStatus('limit-exceeded');
                return;
            }

            // Display the validation errors and error message 
            setCookieStatus('invalid-token');

        })
        .catch(function (error) {
            setCookieStatus('invalid-token');
        });

    }

    // Track changes to the form state
    const handleChange = (fieldName, val) => {
        setDetails({ ...details, [fieldName]: val });
        if (fieldName === "password") updateStrength(val);
    }

    // Check the strength of the password
    const updateStrength = (pw) => {
        // Set up the object
        var newStrength = { ...strength };
        newStrength.score = 0;

        // Check that we have an entry
        newStrength.hasEntry = (pw.length > 0) ? true : false;

        // Is the password long enough
        newStrength.length = (pw.length > 7) ? true : false;
        if (newStrength.length) newStrength.score++;

        // Check for letters and numbers
        var i;
        var character;
        newStrength.number = false;
        newStrength.lc = false;
        newStrength.uc = false;
        for (i = 0; i < pw.length; i++) {

            character = pw.charAt(i);

            // Check for numbers
            if (character !== ' ' && !isNaN(character * 1)) {
                newStrength.number = true;
            } else {

                if (character === character.toUpperCase() && (/^[a-zA-Z0-9]*$/.test(character) === true)) {
                    // Check for uppercase letters
                    newStrength.uc = true;
                } else if (character === character.toLowerCase() && (/^[a-zA-Z0-9]*$/.test(character) === true)) {
                    // Check for lowercase letters
                    newStrength.lc = true;
                }

            }

        }
        if (newStrength.lc) newStrength.score++;
        if (newStrength.uc) newStrength.score++;
        if (newStrength.number) newStrength.score++;

        // Check for special characters
        var pw_test = pw.replace(/ /g, '');
        newStrength.char = false;
        if (/^[a-zA-Z0-9]*$/.test(pw_test) === false && pw.length > 0) {
            newStrength.char = true;
            newStrength.score++;
        }

        // Finally a dictionary check
        // var commonPws = JSON.parse('<?php echo $forbiddenWords; ?>');
        var commonPws = ["password"]
        newStrength.noDict = true;
        var lowerPw = pw.toLowerCase();
        for (var j in commonPws) {
            if (lowerPw.indexOf(commonPws[j]) > -1) {
                newStrength.noDict = false;
                break;
            }
        }
        if (newStrength.noDict) newStrength.score++;

        setStrength(newStrength);

    }

    // Submit the form data via fetch 
    const createUser = ( e ) => {

        e.preventDefault();

        // Clear out any errors
        setError(false);
        setValErrors(false);

        // Set up the data for submission 
        var subData = { ...details };

        let tokenInfo = Encryption.extractTokens(accCookie);
        if (tokenInfo === false) {
            setCookieStatus('no-cookie');
            return;
        }
        subData.token = tokenInfo.token;
        subData.lnkKey = tokenInfo.key;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/public/account", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            body: JSON.stringify(subData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if (data.rslt === 'success' && typeof data.detail.token != "undefined" && typeof data.detail.key != "undefined") {

                const dataToStore = {
                    apiKey: data.detail.key,
                    apiToken: data.detail.token,
                    key: data.detail.key,
                    token: data.detail.token,
                    client: data.detail.usrDetail.Client,
                    email: details.Email,
                    firstName: details.FirstName,
                    lastName: details.LastName,
                    permissions: data.detail.usrDetail.Permissions
                }

                // Upon successful login, store the session info to state (and into an encrypted cookie for more permanent storage)
                var encData = { a: CryptoJS.AES.encrypt(JSON.stringify(dataToStore), encSecret).toString(), t: false };
                setCookie(process.env.REACT_APP_AUTH_COOKIE, encData, { path: '/', secure: true, sameSite: true });

                // Update the header state 
                props.setLoginStatus("user");
                
                // Redirect the form to the next step
                props.updateStep("nxt");
                return;

            } 

            // Display the validation errors and error message 
            setError({ msg: data.msg, style: "red" });
            if ( data.rslt === "invalid-data" ) {
                // Process the validation errors 
                let validationErrors = Fetching.processErrors(data.detail);
                setValErrors(validationErrors);
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error creating the user. Please check your data and try again", style: "red" });
        });

    }

    // On load - check whether there is a cookie set
    /* eslint-disable */
    useState(() => {
        checkCookie();
    }, []);
    /* eslint-enable */

    return (
        <Details 
            error={error}
            valErrors={valErrors}
            handleChange={handleChange}
            createUser={createUser}
            details={details}
            cookieStatus={cookieStatus}
            strength={strength}
            {...props}
        />
    );
}

export default DetailsContainer;
