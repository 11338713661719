import React, {Fragment} from "react";
import { Helmet } from "react-helmet";

import Error from "./../common/forms/error";
import ValidationError from "./../common/forms/validation-error";

import PwMeter from "../users/auth/pw-meter";

const Details = (props) => {

    return (
        <form>
            <Helmet>
                <title>Create an account :: The Coaching Hub</title>
            </Helmet>

            { props.cookieStatus === "ok" &&
                <Fragment>
                    <Error error={props.error} />
                    { props.isRestart && 
                        <Error error={{ msg: "Sorry but there was an error creating your account. Please retry below.", style: "red" }} />
                    }
                
                    <div className="input text">
                        <label htmlFor="firstName">Your first name</label>
                        <input type="text" name="firstName" id="firstName" value={props.details.firstName} onChange={(e) => props.handleChange("firstName", e.target.value)} />
                        <ValidationError fieldName="FirstName" errors={props.valErrors} />    
                    </div>

                    <div className="input text">
                        <label htmlFor="lastName">Your last name</label>
                        <input type="text" name="lastName" id="lastName" value={props.details.lastName} onChange={(e) => props.handleChange("lastName", e.target.value)} />
                        <ValidationError fieldName="LastName" errors={props.valErrors} />    
                    </div>

                    <div className="input text">
                        <label htmlFor="jobTitle">Your job title</label>
                        <input type="text" name="jobTitle" id="jobTitle" value={props.details.jobTitle} onChange={(e) => props.handleChange("jobTitle", e.target.value)} />
                        <ValidationError fieldName="JobTitle" errors={props.valErrors} />
                    </div>

                    <div className="input text email">
                        <label htmlFor="email">Your company email address</label>
                        <input type="email" name="email" id="email" value={props.details.email} onChange={(e) => props.handleChange("email", e.target.value)} />
                        <ValidationError fieldName="Email" errors={props.valErrors} />    
                    </div>

                    <div className="input text password" id="pwForm">
                        <label htmlFor="password">Create a password</label>
                        <input type="password" name="password" id="password" value={props.details.password} onChange={(e) => props.handleChange("password", e.target.value)} />
                        <ValidationError fieldName="Password" errors={props.valErrors} />

                        <PwMeter strength={props.strength} />
                    </div>

                    <button className="btn primary" onClick={(e) => props.createUser(e)}>Next</button>
                </Fragment>
            }

            { props.cookieStatus === "invalid-token" && 
                <Fragment>
                    <h3>Link error</h3>
                    <p>It looks like the link you used to access this portal is invalid.</p>
                    <p>Please try clicking the link again. If the problem persists, please contact your account administrator to check it is correct.</p>
                </Fragment>
            }

            {props.cookieStatus === "limit-exceeded" &&
                <Fragment>
                    <h3>Limit exceeded</h3>
                    <p>It looks like the link you used to access this portal is invalid. Your company has used up all of its licences.</p>
                    <p>Please contact your account administrator to request more licences.</p>
                </Fragment>
            }

            {props.cookieStatus === "no-cookie" &&
                <Fragment>
                    <h4>Unique link required</h4>
                    <p>In order to access the Coaching Hub, your company will need to create an account.</p>
                    <p>If you would like to find out more, please contact our team: hello@thecoachinghub.com</p>
                </Fragment>
            }
        </form>
    );
}

export default Details;
