// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';

// View components
import Verify from '../../../components/users/auth/verify';

const VerifyContainer = (props) => {

    // State management
    const [loading, setLoading] = useState(true);
    const [outcome, setOutcome] = useState(false);
    const [error, setError] = useState(false);

    const urlParams = useParams();

    // Submit the entered passwords
    const doVerify = () => {

        // Submit the login request
        fetch(process.env.REACT_APP_API_BASE + '/public/users/verify/' + urlParams.token + "/" + urlParams.mash, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false);
            if (data.rslt === 'success') {
                setOutcome(true);
                return;
            }           
            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setLoading(false);
            setError({ msg: "Sorry but there was a problem verifying your account. Please refresh the page and try again.", style: "red" });
        });

    }

    // useEffect to load up the auth check when the component mounts
    /* eslint-disable */
    useEffect(() => {
        doVerify();
    }, []);
    /* eslint-enable */

    return (
        <Verify 
            error={error}
            loading={loading}
            outcome={outcome}
        />
    );
}

export default VerifyContainer;
