// Checks a user's auth status and forwards them on accordingly

// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from './../utilities/fetching';
import Encryption from '../utilities/encryption';

// State management
import useAuth from '../store/auth';
import useFlash from '../store/flash';
// import useBookmarks from '../store/bookmarks';
import { useCookies } from 'react-cookie';

// View components
import AuthRouting from '../components/auth-routing';
import Loader from '../components/common/loader';

const AuthRoutingContainer = (props) => {

    // State management
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false); // eslint-disable-line
    const [pause, setPause] = useState(false); // eslint-disable-line
    const [authState, setAuth] = useAuth(false); // eslint-disable-line
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie, setCookie, removeCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    const navigate = useNavigate();
    
    // Upon mount check that the user is not already logged in
    // eslint-disable-next-line
    let checkStatus = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/users/me', {
            method: 'get',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.Email != "undefined" && typeof data.Permissions.IsAdmin != "undefined" && data.Permissions.IsAdmin === true) {

                // Update the global auth state with the relevant info
                setAuth.storeSession({
                    apiToken: authInfo.apiToken,
                    apiKey: authInfo.apiKey,
                    client:data.Client,
                    email:data.Email,
                    firstName:data.FirstName,
                    lastName:data.LastName,
                    manager:data.Manager,
                    mobileNo:data.MobileNo,
                    permissions:data.Permissions,
                    ref:data.Ref
                });

                // TODO: Handle permissions checks as part of the load of the individual pages
                // This allows us to redirect back from those pages, rather than try to redirect back onto yourself
                // It also means that the permissions checks can be carried out on the server-side, minimising round trips to the server

                setLoading(false);
                setPause(false);
                setLoggedIn(true);
                return;
            } 

            // Invalid token - clear the cookie and redirect to the login page
            removeCookie(process.env.REACT_APP_AUTH_COOKIE, { path: '/', secure: true, sameSite: true });
            flashActions.set({ msg: "Sorry, but we could not find the page you are looking for. Please sign in and try again.", style: 'red' });
            navigate("/");
            
        })
        .catch(function (error) {
            // Invalid token - clear the cookie and redirect to the login page
            flashActions.set({ msg: "Sorry, but we couldn't find the page you are looking for. Please sign in and try again.", style: 'red' });
            removeCookie(process.env.REACT_APP_AUTH_COOKIE, { path: '/', secure: true, sameSite: true });
            navigate("/");
        });
    }

    /* eslint-disable */
    useEffect(() => {
        setPause(true);
    //   alert('mocking auth lookup')
        checkStatus();
    }, []);
    /* eslint-enable */

    if (loading) {
        return <Loader />
    }

    return (
        <AuthRouting
            loading={ loading }
            isLoggedIn={ loggedIn }
        />
    );
}

export default AuthRoutingContainer;
