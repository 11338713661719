import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import RoutingContainer from './containers/routing-container';
import AuthRoutingContainer from './containers/auth-routing-container';
import Errors from './components/common/errors';

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/u/*' element={<RoutingContainer />} />
          <Route exact path='/' element={<RoutingContainer />} />
          <Route path='/admin/*' element={<AuthRoutingContainer />} />
          <Route path='/*' element={<RoutingContainer />} />
          <Route element={<Errors />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
