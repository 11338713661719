// Core react and plugins
import React from 'react';
import Footer from '../../components/common/footer';

const FooterContainer = (props) => {
    return (
        <Footer isAdmin={ props.isAdmin } />
    );
}

export default FooterContainer;