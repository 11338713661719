// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import CreateCoach from '../../../components/admin/coaches/create';
import useFlash from '../../../store/flash';

const CoachCreateContainer = () => {

    // State management
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [valErrors, setValErrors] = useState(false);
    const [coach, setCoach] = useState({ firstName: "", lastName: "", emailAddress: "", summary: "" });

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line
    const [flashState, flashActions] = useFlash(); // eslint-disable-line

    const navigate = useNavigate();
    const { coachId } = useParams();

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadCoach = () => {

        setLoading(true);

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Get the report ID from the URL
        if (coachId === "create") {
            return;
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/coaches/' + coachId, {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false)
            if (data.rslt === "success") {
                setCoach(data.detail);
                return;
            }

            setError({ msg: data.msg, style: "red"});

        })
        .catch(function (error) {
            setError({ msg: "Sorry, but we could not load the coaches.", style: "red"})
        });
    }

    // Handle the updates to the form
    const handleUpdate = (e) => {
        e.preventDefault();
        var newCoach = {...coach};
        newCoach[e.target.name] = e.target.value;
        setCoach(newCoach);
    }

    // Handle the save 
    const handleSave = (e) => {
        e.preventDefault();
    
        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Set up the url and approach to the save 
        let url = process.env.REACT_APP_API_BASE + '/coaches/' + coachId;
        let method = "put";
        if (coachId === "create") {
            url = process.env.REACT_APP_API_BASE + '/coaches';
            method = "post";
        }

        // Do the save
        fetch(url, {
            method: method,
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(coach)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false)
            if (data.rslt === "success") {
                flashActions.set({ msg: "The coach has been successfully saved.", style: "grn" });
                navigate("/admin/coaches");
                return;
            }

            setError({ msg: data.msg, style: "red"});
            // Process the validation errors
            if (data.detail) {
                var errs = Fetching.processErrors(data.detail);
                console.log(errs);
                setValErrors(errs);
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry, but we could not load the coaches.", style: "red"})
        });

    }

    /* eslint-disable */
    useEffect(() => {
        loadCoach();
    },[])
    /* eslint-enable */

    return (
        <CreateCoach 
            loading={loading}
            error={error}
            valErrors={valErrors}
            coach={coach}
            handleUpdate={handleUpdate}
            handleSave={handleSave}
        />
    );
}

export default CoachCreateContainer;
