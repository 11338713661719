import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

// View components
import Error from "./../common/forms/error";
import Loader from "../common/loader";

const Submit = (props) => {

    return (
        <div style={{ overflow: "auto" }}>
            <Helmet>
                <title>Complete submission :: The Coaching Hub</title>
            </Helmet>

            <Error error={props.error} />

            { props.loading ? (
                <Loader />
            ) : (
                <Fragment>
                    { props.details.answers.map( (ans, i) => 
                        <div className="summary-ans" key={ "ans-" + i }>
                            <h6 dangerouslySetInnerHTML={{ __html: "Question " + (i + 1) + ": " + ans.question }}></h6>
                            <p dangerouslySetInnerHTML={{ __html: ans.answer }}></p>
                        </div>
                    )}
                </Fragment>
            )}

            <button className="btn secondary left" onClick={(e) => props.goBack(e)}>Previous</button>
            <button className="btn primary right" onClick={(e) => props.submitReport(e)}>Submit Profile</button>

        </div>
    );
}

export default Submit;
