// Core react and plugins
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Password from '../../common/forms/password';
import CheerMan from './../../../assets/img/ch/man-cheers.png';

// Components
import Error from './../../common/forms/error';
import PwMeter from './pw-meter';
import SetupHeader from '../../common/setup-header';

// Component class itself
function SetPw(props) {

    return (
        <div className="questionnaire short">
            <Helmet>
                <title>Set Password :: The Coaching Hub</title>
            </Helmet>

            <SetupHeader title="Set Password" subTitle={false} />

            <div className="q-content">
                <div className="q-steps">

                    <Error error={ props.error } />

                    <form>

                        <PwMeter { ...props } /> 
                        <Password label={'Password'} id={'password'} handleChange={ (e) => props.handleUpdate("password", e)} />
                        <Password label={'Confirm Password'} id={'confirm'} handleChange={ (e) => props.handleUpdate("confirm", e)} />

                        <button onClick={ (e) => props.submitFunc(e) } className="btn primary left">{ props.btnTxt }</button>
                        <p className="sml right">Forgotten your password? <Link to="/u/forgotten-password">Reset it here</Link></p>
                    </form>

                </div>
            </div>

            <div className="side-img lft">
                <img src={CheerMan} alt="A man celerating" />
            </div>

            <div className="q-side rght"></div>
        </div>

    );

}

export default SetPw;