import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Permissions from '../../utilities/permissions';

// Assets
import Logo from './../../assets/img/ch/coaching-hub-logo.svg';
import CreatedBy from './../../assets/img/ch/created-by.svg';

function Header(props){

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    return (
        <header className={ props.isAdmin && !props.isExternal ? "admin" : "public"}>

            <div className="content">
                <div className={props.isAdmin ? 'width-6 logo full-admin' : 'width-6 logo'}>
                    <img src={Logo} alt="The Coaching Hub" className="ch-logo" />
                    <img src={CreatedBy} alt="Created by Abstract UK" className="cb-logo" />       
                </div>

                <div className={ props.isAdmin ? 'width-6 main-nav full-admin' : 'width-6 main-nav'}>
                    <button className={ menuOpen ? "menu-toggle open" : "menu-toggle"} onClick={ (e) => toggleMenu(e) }>Menu</button>

                    <div className={ menuOpen ? 'menu open' : 'menu'}>
                        <nav>
                            <ul>
                                { !props.isLoggedIn ? (
                                    <li><Link to='/u/login' className={ props.pathname === "/u/login" ? "current" : "" }>Sign In</Link></li>
                                ) : (
                                    <Fragment>
                                        { props.isAdmin ? (
                                            <Fragment>
                                                <li><Link to='/admin/reports' className={props.pathname === "/admin/reports" ? "current" : ""}>Reports</Link></li>
                                                { Permissions.actionCheck("clients", "manage", props.permissions) &&
                                                    <li><Link to='/admin/clients' className={props.pathname === "/admin/clients" ? "current" : ""}>Clients</Link></li>
                                                }
                                                { Permissions.actionCheck("coaches", "manage", props.permissions) &&
                                                    <li><Link to='/admin/coaches' className={props.pathname === "/admin/coaches" ? "current" : ""}>Coaches</Link></li>
                                                }
                                                { Permissions.actionCheck("users", "manage", props.permissions) &&
                                                    <li><Link to='/admin/users' className={props.pathname === "/admin/users" ? "current" : ""}>Admin Users</Link></li>
                                                }
                                                <li><Link to='/u/logout' className={props.pathname === "/u/logout" ? "current" : ""}>Sign out</Link></li>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <li><Link to='/u/logout' className={props.pathname === "/u/logout" ? "current" : ""}>Sign Out</Link></li>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header