import React from 'react';
import Button from '../../common/forms/button';
import { Navigate } from 'react-router-dom';
import ValidationError from '../../common/forms/validation-error';
import Error from '../../common/forms/error';
import Password from '../../common/forms/password';

function ChangeDetails(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    return (
        <div className="form-scrn">

            <form>

                <h1>Update my password</h1>

                <Error error={props.error}/>
                
                <Password label='New password' id='password' value={props.settings.password} handleChange={(e) => props.handleUpdate('password', e)} />
                <Password label='Confirm new password' id='confirm' value={props.settings.confirm} handleChange={(e) => props.handleUpdate('confirm', e)} />
                <ValidationError errors={props.valErrors} fieldName='email'/>

                <hr className='password-divider'/>
                <Password label='Current password' id='current' value={props.settings.current} handleChange={(e) => props.handleUpdate('current',e) }/>
                <ValidationError errors={props.valErrors} fieldName='password'/>
                <Button btnTxt={props.btnTxt} id='save_btn' handleClick={props.savePw}/>
            </form>
  
        </div>
    )
}

export default ChangeDetails