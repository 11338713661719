// Core react and plugins
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import Loader from '../../common/loader';
import Error from '../../common/forms/error';
import DownloadContainer from '../../../containers/admin/reports/download-container';

const ReportView = (props) => {

    return (
        <div className="content admin-content">
            <Helmet>
                <title>View report :: The Coaching Hub</title>
            </Helmet>
            
            <div className="width-8 centred">
                <DownloadContainer {...props} />

                <h1>View report{props.report && typeof props.report.user !== "undefined" && typeof props.report.user.firstName !== "undefined" ? ": " + props.report.user.firstName + " " + props.report.user.lastName : "" }</h1>

                <Error error={props.error} />

                { props.loading || typeof props.report.user === "undefined" || typeof props.report.user.firstName === "undefined" ? (
                    <Loader />
                ) : (
                    <Fragment>
                        <h4>User details</h4>
                        <table className="mbtm">
                            <tbody>
                                <tr>
                                    <td>User name</td>
                                    <td>{ props.report.user.firstName } { props.report.user.lastName }</td>
                                </tr>
                                <tr>
                                    <td>Email address</td>
                                    <td>{props.report.user.email}</td>
                                </tr>
                                <tr>
                                    <td>Job title</td>
                                    <td>{ props.report.user.jobTitle }</td>
                                </tr>
                                <tr>
                                    <td>Client</td>
                                    <td>{ props.report.client.clientName }</td>
                                </tr>
                            </tbody>
                        </table>

                        <h4>User profile</h4>

                        { props.report.answers.map((answer, index) =>
                            <div className="summary-ans" key={ "ans-" + index }>
                                <h6 dangerouslySetInnerHTML={{ __html: "Question " + (index + 1) + ": " + answer.question.question }}></h6>
                                <p dangerouslySetInnerHTML={{ __html: answer.answer }}></p>
                            </div>
                        )}
                    </Fragment>
                )}

                <form className="admin-form">

                    <h4>Select the best coach to fit this profile</h4>
                
                    <div className="input select">
                        <label htmlFor="coach">Choose the most appropriate coach from the list below</label>
                        <select name="coach" id="coach" value={ typeof props.coach !== "undefined" ? props.coach : "" } onChange={ (e) => props.updateCoach(e.target.value) }>
                            <option value="">Please select...</option>
                            { props.coaches.map((coach, index) => 
                                <option key={ "coach-" + index } value={ coach.id }>{ coach.firstName } { coach.lastName }</option>
                            )}
                        </select>
                    </div>

                    <button className="btn primary" onClick={ (e) => props.saveCoach(e) }>Assign coach</button>

                </form>
            </div>

        </div>
    );
}

export default ReportView;
