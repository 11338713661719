// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../utilities/fetching';

// State management
import { useCookies } from 'react-cookie';

// View components
import UpdatePassword from '../../../components/users/auth/update-password';
import Encryption from '../../../utilities/encryption';

const UpdatePasswordContainer = (props) => {

    let initialBtnText = "Update"

    // State management
    const [error, setError] = useState(false);
    const [btnTxt, setBtnTxt] = useState(initialBtnText);
    const [settings, setSettings] = useState({ password: "", confirm: "", current: "" }); // eslint-disable-line
    const [permissions, setPermissions] = useState({ Reports: false, Users: false, Questionnaire: false, Clients: false }); // eslint-disable-line
    const [valErrors, setValErrors] = useState(false);
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);

    // Handle the field updates 
    const handleUpdate = (fieldname, e) => {
        var newSettings = { ...settings };
        newSettings[fieldname] = e.target.value;
        setSettings(newSettings);
    }

    // Submit the details for saving
    const savePw = ( e ) => {

        e.preventDefault();
        setError(false);
        setValErrors({})

        setBtnTxt("Updating...");

        let dataToPost = {
            password:settings.password,
            confirm:settings.confirm,
            current:settings.current
        }

        // Check the auth cookie for auth Info 
        let authInfo = Encryption.extractHeaders(authCookie);

        var url = '/users/update-password';
        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(dataToPost)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setBtnTxt("Update");
            if(data.rslt === 'success' ){
                setError({ msg: "Your password has been updated successfully.", style: "grn" });
                return;
            }
            if (data.rslt === 'match-error') {
                setError({ msg: "Sorry, there was error updating your password. Please check that the new password and confirmation match.", style: "red" });
                return;
            }
            setError({ msg: "Sorry, there was an error updating your password.", style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "Sorry, there was an error updating your password.", style: "red" });
            setBtnTxt("Update");
        });

    }

    /* eslint-disable */
    useEffect(() => {
        document.title = 'Edit Account :: The Coaching Hub';
    },[]);
    /* eslint-enable */

    return (
        <UpdatePassword
            error={ error }
            valErrors={ valErrors }
            handleUpdate={ handleUpdate }
            savePw={savePw}
            permissions={permissions}
            settings={settings}
            btnTxt={ btnTxt }
        />
    );
}

export default UpdatePasswordContainer;
