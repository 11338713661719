// Core react and plugins
import React, { useState, useEffect } from 'react';

// Utilities
import Fetching from '../../../utilities/fetching';

// State management
import { useCookies } from 'react-cookie';
import useFlash from '../../../store/flash';

// View components
import ChangeDetails from '../../../components/users/auth/change-details';
import Encryption from '../../../utilities/encryption';
const ChangeDetailsContainer = (props) => {


    let isUserEdit = true;
    let initialBtnText = "Save"

    // State management
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [error, setError] = useState(false);
    const [btnTxt, setBtnTxt] = useState(initialBtnText);
    const [settings, setSettings] = useState({ firstName: "", lastName: "", email: "" }); // eslint-disable-line
    const [permissions, setPermissions] = useState({ Reports: false, Users: false, Questionnaire: false, Clients: false }); // eslint-disable-line
    const [returnEmailLink, setReturnEmailLink] = useState(false); // eslint-disable-line
    const [valErrors, setValErrors] = useState(false);
    const [flashState, flashActions] = useFlash(); // eslint-disable-line
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [clientId] = useState(-1)
    const [userManager,setUserManager] = useState({})

    const [emailLink,setEmailLink] = useState(false)
    const [lightBoxOpen,toggleLightBoxOpen] = useState(false)

    // Handle the field updates 
    const handleUpdate = (fieldname, e) => {
        var newSettings = { ...settings };
        newSettings[fieldname] = e.target.value;
        setSettings(newSettings);
    }

    // Submit the details for saving
    const saveUser = ( e ) => {

        e.preventDefault();
        setError(false);
        setValErrors({})

        var nbtn = "Saving...";
        setBtnTxt(nbtn);

        let dataToPost = {
            password:settings.password,
            firstName:settings.firstName,
            lastName:settings.lastName,
            email:settings.email,
            phone:settings.phone,
        }

        // Get the cookie data 
        let authInfo = Encryption.extractHeaders(authCookie);

        var url = '/users/update'  ;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + url, {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(dataToPost)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            if(data.rslt === 'success' ){
                if( data.detail != null){
                    setEmailLink(data.detail)
                    toggleLightBoxOpen(true)
                } else {

                    let message = 'Your account has been updated'

                    setError({ msg: message, style: "grn" });
                }
                
            } else if (data.rslt === 'unapproved'){
                setError({ msg: "Sorry, please ensure the user details are filled out correctly", style: "red" });
                setValErrors(
                    {
                        'password':['Please enter the correct password'],
                   }
                );

            } else if (data.rslt === 'usr-valid-error' || data.rslt === "invalid-data"){
                setError({ msg: "Sorry, please ensure the user details are filled out correctly", style: "red" });
                
            }else if (data.rslt === 'save-error'){
                setError({ msg: "Sorry, there was an error saving on the server", style: "red" });
                
            } else if (data.rslt === 'usr-exists'){
                setError({ msg: "Sorry, there was an error saving", style: "red" });
                setValErrors(
                    {
                        'email':['This email address already exists in an account'],
                   }
                );
            } else {
                setError({ msg: "Sorry, there was an error saving", style: "red" });
            }

            var nbtn = "Save";

            setBtnTxt(nbtn);


        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error saving the user. Please check your data and try again", style: "red" });
            var nbtn = "Save";

            setBtnTxt(nbtn);
        });

    }

    // Load up existing user details
    const loadUsr = () => {

        // return;

        setLoading(true);

        // Extract the auth headers 
        let authInfo = Encryption.extractHeaders(authCookie);
        
        fetch(process.env.REACT_APP_API_BASE + '/users/me' , {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
 
            let newSettings = {
                phone:data.MobileNo,
                email:data.Email,
                firstName:data.FirstName,
                lastName:data.LastName
            }
            setSettings(newSettings)

            setLoading(false);

        })
        .catch(function (error) {
            setError({ msg: "Sorry but we could not find the user you are looking for. PLease try again.", style: "red" });
        });        
    }

    /* eslint-disable */
    useEffect(() => {
        loadUsr()
        document.title = 'Edit Account :: The Coaching Hub';
    },[]);
    /* eslint-enable */

    return (
        <ChangeDetails
            error={ error }
            valErrors={ valErrors }
            handleUpdate={ handleUpdate }
            saveUser={saveUser}
            setReturnEmailLink={setReturnEmailLink}
            clientId={clientId}
            permissions={permissions}
            settings={settings}
            userManager={userManager}
            setUserManager={setUserManager}
            isUserEdit={isUserEdit}
            btnTxt={ btnTxt }
            emailLink={emailLink}
            lightBoxOpen={lightBoxOpen}
            toggleLightBoxOpen={toggleLightBoxOpen}
            // timezones={ timezones }
        />
    );
}

export default ChangeDetailsContainer;
