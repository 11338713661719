import React from 'react';
import { Helmet } from "react-helmet";
import Loader from '../common/loader';

import Error from './../common/forms/error';
import ValidationError from './../common/forms/validation-error';

const Questions = (props) => {

    if (props.questions.length === 0) {
        return <Loader />
    }

    let currentQuestion = props.questions[props.current];

    return (
        <form id="pwForm">
            <Helmet>
                <title>Questionnaire :: The Coaching Hub</title>
            </Helmet>

            <Error error={props.error} />

            <div className="input text password">
                <label htmlFor="question" dangerouslySetInnerHTML={{ __html: currentQuestion.question }}></label>
                <textarea name="question" id="question" value={ props.ans } onChange={(e) => props.handleChange(e.target.value)} />
                <ValidationError errors={props.valErrors} fieldName="Question" />
            </div>

            { props.current === (props.questions.length - 1) ? (
                <button className="btn primary right" onClick={(e) => props.saveQuestion(e, 'nxt', true)}>Finish</button>
            ) : (
                <button className="btn primary right" onClick={(e) => props.saveQuestion(e, 'nxt', false)}>Next</button>
            )}
            { props.current > 0 &&
                <button className="btn secondary left" onClick={(e) => props.saveQuestion(e, 'back', false)}>Previous</button>
            }
        </form>
    );
}

export default Questions;
