// Core react and plugins
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import { Link } from 'react-router-dom';
import Loader from '../../common/loader';
import Error from '../../common/forms/error';

const CoachesList = (props) => {

    return (
        <div className="content admin-content">
            <Helmet>
                <title>Manage coaches :: The Coaching Hub</title>
            </Helmet>
            
            <div className="width-9 centred">
                <Link to="/admin/coach/create" className="btn primary sml right">Create coach</Link>
                <h1>Manage Coaches</h1>

                <Error error={ props.error } />

                <table>
                    <thead>
                        <tr>
                            <th>Coach name</th>
                            <th>Areas of expertise</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>

                        { props.loading ? (
                            <tr>
                                <td colSpan="5" className='centre white'>
                                    <Loader />
                                </td>
                            </tr>
                        ) : (
                            <Fragment>
                                { props.coaches.length === 0 ? (
                                    <tr>
                                        <td colSpan="5" className='centre empty'>There are no coaches to display</td>
                                    </tr>
                                ) : (
                                    <Fragment>
                                        { props.coaches.map((coach, index) => (
                                            <tr key={ index }>
                                                <td>{ coach.firstName } { coach.lastName }</td>
                                                <td className="sml-hide" dangerouslySetInnerHTML={{ __html: coach.summary }}></td>
                                                <td>
                                                    <Link to={`/admin/coach/${coach.id}`} className="btn primary">Edit</Link>
                                                    <button className="btn secondary" onClick={ (e) => props.handleDelete(e, coach.id) }>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </tbody>
                </table>

            </div>

        </div>
    );
}

export default CoachesList;
