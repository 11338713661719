import React from 'react';

const ProgressBar = (props) => {

    return (
        <div className="progress-bar">

            <ul>
                { props.questions.map((question, index) =>  
                    <li key={index} className={ props.current === index ? "active" : props.current > index ? "completed" : ""}>&nbsp;</li>
                )}
            </ul> 

        </div>
    );
}

export default ProgressBar;
