// Core react and plugins
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';

// Utilities
import CryptoJS from 'crypto-js';

import Landing from '../../components/landing/landing';

const LandingContainer = (props) => {

    // Handle the location 
    let encSecret = process.env.REACT_APP_ENC_SECRET;
    const urlParams = useParams();

    const [accCookie, setCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    const trackTokens = (tokens) => {

        // Set the data for the cookie 
        let cookieData = {
            token: urlParams.token,
            key: urlParams.key
        };

        // Encrypt + set the cookie
        var encData = { t: CryptoJS.AES.encrypt(JSON.stringify(cookieData), encSecret).toString() };
        setCookie(process.env.REACT_APP_AUTH_COOKIE, encData, { path: '/', secure: true, sameSite: true });

    }

    // On page load check whether a token and key are present in the URL
    /* eslint-disable */
    useEffect(() => {
        if (typeof urlParams.token != "undefined" && typeof urlParams.key != "undefined") {
            trackTokens();
        }
    }, []);
    /* eslint-enable */

    return (
        <Landing />
    );
}

export default LandingContainer;