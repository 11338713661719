import React from 'react';

function Button(props){

    return (
        <button onClick={ (e) => props.handleClick(e) } className="btn primary">{ props.btnTxt }</button>
    )
}

export default Button
