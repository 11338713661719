import React, { Fragment } from 'react';
import Error from '../../common/forms/error';

import ValidationError from '../../common/forms/validation-error';

function CreateClient(props){

    const pageName = (!props.isClientEdit) ? 'Client a new client' : 'Edit client details';

    return (
        <div className="content admin-content">
            <section className="width-9 centred">
                <h1>{pageName}</h1>

                <Error error={ props.error } />

                <div className="input text">
                    <label htmlFor="name">Client name</label>
                    <input type="text" name="clientName" id="name" value={props.details.clientName} onChange={(e) => props.handleUpdate(e)} />
                    <ValidationError fieldName="ClientName" errors={props.valErrors} />
                </div>

                <div className="input number">
                    <label htmlFor="name">Licences</label>
                    <input type="number" name="licences" id="licences" value={props.details.licences} onChange={(e) => props.handleUpdate(e)} />
                    <ValidationError fieldName="Licences" errors={props.valErrors} />
                </div>

                <div className="variable-inputs">

                    { props.details.domains.length === 0 ? (
                        <div className="input text" key={"dom-0"}>
                            <button className="remove-btn right" onClick={(e) => props.removeDomain(e,0)}>Remove</button>
                            <label htmlFor={"domain0"}>Approved domain 1</label>
                            <input type="text" name={"domain0"} id={"domain0"} value={typeof props.details.domains[0] != "undefined" ? props.details.domains[0] : ""} onChange={(e) => props.handleUpdate(e)} />
                            <ValidationError fieldName={"Domains[0]"} errors={props.valErrors} />
                        </div>
                    ) : (
                        <Fragment>
                            { props.details.domains.map ( (domain,i) => 
                                <div className="input text" key={ "dom-" + i }>
                                    <button className="remove-btn right" onClick={(e) => props.removeDomain(e, i)}>Remove</button>
                                    <label htmlFor={"domain" + i}>Approved domain { i + 1 }</label>
                                    <input type="text" name={"domain" + i} id={"domain" + i} value={typeof props.details.domains[i] != "undefined" ? props.details.domains[i] : ""} onChange={(e) => props.handleUpdate(e)} />
                                    <ValidationError fieldName={"Domains[" + i + "]"} errors={props.valErrors} />
                                </div>
                            )}
                        </Fragment>
                    )}

                    <button className="add-btn" onClick={(e) => props.addDomain(e)}>Add another domain</button>

                </div>
                
                <button className="btn primary" onClick={(e) => props.saveClient(e)}>{ props.btnTxt }</button>
            </section>
  
        </div>
    )
}

export default CreateClient