// Core react and plugins
import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import Password from '../../common/forms/password';
import TextInput from '../../common/forms/text-input';

// Components
import SetupHeader from './../../common/setup-header';
import Error from './../../../components/common/forms/error';
import Button from '../../common/forms/button';

import CheerMan from './../../../assets/img/ch/man-cheers.png';
import LaptopLady from './../../../assets/img/ch/right-girl.png';

// Component class itself
function Login(props) {

    return (
        <div className="questionnaire blue">
            <Helmet>
                <title>Sign In :: The Coaching Hub</title>
            </Helmet>
            
            <SetupHeader title="Sign In" subTitle={ "Sign in to your account to complete the questionnaire and create your profile." } />

            <div className="q-content">
                <div className="q-steps">

                    <Error error={ props.error } />

                    <form>

                        <TextInput label='Email' id='email' handleChange={(e) => props.handleAuthInfo('email',e) } />
                        <Password label='Password' id='password' handleChange={(e) => props.handleAuthInfo('password',e)} />

                        <Button handleClick={ (e) => props.loginFunc(e) } btnTxt={ props.btnTxt }/>
                        <p className="sml centred">Forgotten your password? <Link to="/u/forgotten-password">Reset it here</Link></p>
                    </form>

                </div>
            </div>

            <div className="q-side lft"></div>
            <div className="side-img lft">
                <img src={ CheerMan } alt="A man celerating" />
            </div>

            <div className="q-side rght"></div>
            <div className="side-img rght">
                <img src={LaptopLady} alt="A happy lady using a laptop" />
            </div>
        </div>
    );

}

export default Login;