// Core react and plugins
import React, { useState, useEffect } from 'react';
import {useCookies} from 'react-cookie';

// View components
import Routing from './../components/routing';

import Fetching from '../utilities/fetching';
import Encryption from '../utilities/encryption';

const RoutingContainer = (props) => {

    // State management
    const [loginStatus, setLoginStatus] = useState(false); // eslint-disable-line

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    
    // Upon mount check that the user is not already logged in
    // eslint-disable-next-line
    let checkStatus = () => {

        // Get the auth data from the cookie
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/users/me', {
            method: 'get',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.Email != "undefined" && typeof data.Permissions.IsAdmin != "undefined" && data.Permissions.IsAdmin === true) {

                setLoginStatus("admin");
                return;

            } else if (typeof data.Email != "undefined" && typeof data.Permissions.IsAdmin != "undefined" && data.Permissions.IsAdmin === false) {

                setLoginStatus("user");
                return;

            }

        })
        .catch(function (error) {});
    }

    /* eslint-disable */
    useEffect(() => {
        checkStatus();
    }, []);
    /* eslint-enable */

    return (
        <Routing
            loginStatus={loginStatus}
            setLoginStatus={setLoginStatus}
        />
    );
}

export default RoutingContainer;
