import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

// Utilities 
import Fetching from "./../../utilities/fetching";
import Encryption from "./../../utilities/encryption";

// View components
import Questions from "../../components/questionnaire/step-questions";

const QuestionsContainer = (props) => {


    // Track state for this screen
    const [ans, setAns] = useState("");
    const [cur, setCur] = useState("-");
    const [error, setError] = useState(false);


    // Cookie / auth
    const [accCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    // Track changes to the form state
    const handleChange = (val) => {
        setAns(val);
    }

    // Load up the question responses (if there are existing)
    const loadAnswer = () => {

        // Get the auth Info
        let tokenInfo = Encryption.extractHeaders(accCookie);

        // Get the question ID 
        let qId = getCurrentQuestionId();

        // get the answer from the server 
        fetch(process.env.REACT_APP_API_BASE + "/questionnaire/answer/" + qId, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                "api-token": tokenInfo.token,
                "api-key": tokenInfo.key
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                setAns(data.detail);
                return;

            } 

            // Handle validation errors
            setAns("");

        })
        .catch(function (error) {
            setAns("");
        });

    }

    // Handle the movement of the questions when they have been answered / moved backwards
    const saveQuestion = ( e, dir, redirect ) => {

        e.preventDefault();

        // Get the auth Info 
        let tokenInfo = Encryption.extractHeaders(accCookie);

        // Set up tthe request body 
        let reqBody = {
            questionId: getCurrentQuestionId(),
            answer: ans
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/questionnaire", {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                "api-token": tokenInfo.token,
                "api-key": tokenInfo.key
            },
            body: JSON.stringify(reqBody)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Handle successful submission
            if(data.rslt === 'success' ){
                
                setAns("");
                // Hide the existing question 
                if (redirect) {
                    props.updateStep(dir, false);
                    return;
                }
                props.updateStep(dir, true);
                return;

            } 

            // Handle validation errors
            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error saving your answer. Please check your entry and try again", style: "red" });
        });
        
    }

    // Return the current question ID
    const getCurrentQuestionId = () => {
        if (typeof props.questions[props.current] !== "undefined") {
            return props.questions[props.current].id;
        }
        return false;
    }

    /* eslint-disable */
    useEffect(() => {
        if (props.current !== false && props.questions.length > 0 && props.current !== cur) {
            loadAnswer();
            setCur(props.current);
        }
    }, [props.current, props.questions]);
    /* eslint-enable */

    return (
        <Questions
            error={error}
            saveQuestion={saveQuestion}
            handleChange={handleChange}
            ans={ans}
            {...props}
        />
    );
}

export default QuestionsContainer;
