// Core react and plugins
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import { Link } from 'react-router-dom';
import Loader from '../../common/loader';
import Error from '../../common/forms/error';
import Permissions from '../../../utilities/permissions';

const ReportsList = (props) => {

    // Function to replace the first letter of a string with a capital letter
    const processStatus = (string) => {
        string = string.charAt(0).toUpperCase() + string.slice(1);
        string = string.replace(/-/g, " ");
        return string;
    }

    const getPasswordResetLightboxContent = (passwordResetUrl) => {
        let lightboxViewContent = <Fragment>
            <p>You can send the link below to this user so they can reset their password for their account.</p>
            <div className="highlight">
                <p className="lnk-wrap">{passwordResetUrl}</p>
            </div>
        </Fragment>

        return lightboxViewContent
    }

    let reportListPopup = null
    let lightboxViewTitle = '';
    let lightboxViewContent = null;

    if (props.lightboxView === 'resetPassword') {
        lightboxViewTitle = 'User password reset'
        lightboxViewContent = getPasswordResetLightboxContent(props.passwordResetUrl)
    }

    if (props.lightBoxOpen) {
        reportListPopup = <div className="cover">
            <Link to='' onClick={(e) => props.closeLightbox(e)} className="close-btn">Close</Link>
            <div className="lb">
                <h3>{lightboxViewTitle}</h3>
                {props.lbError &&
                    <div className={props.lbError.class}>
                        <p>{props.lbError.msg}</p>
                    </div>
                }
                {lightboxViewContent}
            </div>
        </div>
    }

    return (
        <div className="content admin-content">
            <Helmet>
                <title>Manage reports :: The Coaching Hub</title>
            </Helmet>
            {reportListPopup}
            
            <div className="width-12">
                <h1>Manage Reports and Users</h1>
            </div>

            <div className="width-9">

                <Error error={ props.error } />

                <table>
                    <thead>
                        <tr>
                            <th>User name</th>
                            <th className="sml-hide">Client</th>
                            <th>Status</th>
                            <th className="mid-hide">Coach</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>

                        { props.loading && props.reports.length === 0 ? (
                            <tr>
                                <td colSpan="5" className='centre'>
                                    <Loader />
                                </td>
                            </tr>
                        ) : (
                            <Fragment>
                                { props.reports.length === 0 ? (
                                    <tr>
                                        <td colSpan="5" className='centre empty'>There are no reports to display</td>
                                    </tr>
                                ) : (
                                    <Fragment>
                                        { props.reports.map((report, index) => (
                                            <tr key={ index }>
                                                <td>{ report.user.firstName } { report.user.lastName }</td>
                                                <td className="sml-hide">{ report.client.clientName }</td>
                                                <td><span className={ report.status }>{ processStatus(report.status) }</span></td>
                                                <td className="mid-hide">
                                                    { typeof report.coach.firstName !== "undefined" ? (
                                                        <Fragment>{report.coach.firstName} { report.coach.lastName }</Fragment>
                                                    ) : "-"}
                                                </td>
                                                <td>
                                                    { report.status === "submitted" &&
                                                        <Link to={ "/admin/reports/" + report.id } className="btn primary">Assign coach</Link>
                                                    }
                                                    { props.permissions && Permissions.actionCheck("users", "pwd", props.permissions) &&
                                                        <Link to='' onClick={(e) => props.resetUsrPw(report.user.id, e)} className="btn">Password</Link>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </tbody>
                </table>

            </div>

            <div className="width-3 filter">
                <h2>Filter users</h2>

                <div className="input text">
                    <label htmlFor="usrName">User name</label>
                    <input type="text" name="usrName" id="usrName" onChange={ (e) => props.updateFilters(e) } />
                </div>

                <div className="input select">
                    <label htmlFor="client">User name</label>
                    <select name="clientId" id="client" onChange={(e) => props.updateFilters(e)} value={ props.filters.clientId }>
                        <option value="">Select a client</option>
                        { props.clients.map((client, index) => 
                            <option key={ index } value={ client.id }>{ client.clientName }</option>
                        )}
                    </select>
                </div>

                <div className="input checkbox">
                    <input type="checkbox" name="incAssigned" id="incAssigned" onChange={(e) => props.updateFilters(e)} />
                    <label htmlFor="incAssigned">Include reports for users who already have coaches assigned to them</label>
                </div>

                <button className="btn primary sml" onClick={ () => props.loadReports(false, true) }>Filter</button>
            </div>
        </div>
    );
}

export default ReportsList;
