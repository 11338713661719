import React from 'react';

import Abstract from './../../assets/img/ch/abstractuk.svg';

function Footer(props){

    return (
        <footer className={ props.isAdmin ? "admin" : "" }>
            <div className="content">
                <p className='powered'>Created by:</p>
                <img src={ Abstract } className="footer-logo" alt="Abstract logo" />
                <p>&copy; 2023 AbstractUK</p>
            </div>
        </footer>
    )
}

export default Footer