// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import Download from '../../../components/admin/reports/download';

const DownloadContainer = (props) => {

    // State management
    const [report, setReport] = useState(false);
    const [btnTxt, setBtnTxt] = useState("Download the report");

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line

    const params = useParams();

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadReport = () => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Get the report ID from the URL
        let reportId = params.repRef;

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/reports/' + reportId, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setReport(data.detail);
                return;
            }

            props.setError({ msg: data.msg, style: "red"});

        })
        .catch(function (error) {
            props.setError({ msg: "Sorry, but we could not load the reports.", style: "red"})
        });
    }

    /* eslint-disable */
    useEffect(() => {
        loadReport();
    },[])
    /* eslint-enable */

    return (
        <Download 
            report={report}
            btnTxt={btnTxt}
            setBtnTxt={setBtnTxt}
        />
    );
}

export default DownloadContainer;
