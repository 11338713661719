// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Utilities
import Fetching from '../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import ReportsList from '../../../components/admin/reports';

const ReportsListContainer = () => {

    // State management
    const [filters, setFilters] = useState({ usrName: "", clientId: "", incAssigned: false }); // eslint-disable-line
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [complete, setComplete] = useState(false);
    const [page, setPage] = useState(1);
    const [error, setError] = useState(false);
    const [reports, setReports] = useState([]);
    const [clients, setClients] = useState([]);
    const [lightBoxOpen, toggleLightBoxOpen] = useState(false)
    const [lightboxView, setLightBoxView] = useState(false)
    const [passwordResetUrl, setPasswordResetUrl] = useState(false)
    const [lbError, setLbError] = useState(false);
    const [permissions, setPermissions] = useState(false);

    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]); // eslint-disable-line
    const navigate = useNavigate();

    // Handle the update of the filters
    const updateFilters = (e) => {

        let newFilters = {...filters};
        if (e.target.type === "checkbox") {
            newFilters[e.target.name] = e.target.checked;
        } else {
            newFilters[e.target.name] = e.target.value;
        }
        setFilters(newFilters);

    }

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadReports = (e, reset) => {
        if (typeof e != "undefined" && e) e.preventDefault();
        if (typeof reset == "undefined") reset = false;

        // If it is complete then 
        if (complete && !reset) return;

        setLoading(true);

        // If it is a reset then set the page to page 1
        let usePage = page;
        if (reset) {
            setPage(1);
            usePage = 1;
            setComplete(false);
        }

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/reports?page=' + usePage, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(filters)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            setLoading(false)
            if (reset) {
                setReports(data.detail)
            } else {
                let newReports = [...reports, ...data.detail];
                setReports(newReports)
            }
            setPage(page + 1);
            
            if (data.detail < 25) {
                setComplete(false, true);
            }

        })
        .catch(function (error) {
            if (error === "auth") {
                navigate("/u/login");
            }
            setError({ msg: "Sorry, but we could not load the reports.", style: "red"})
        });
    }

    // Load up the clients for the filter 
    const loadClients = () => {

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/clients/list', {
            method: 'get',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (data.rslt === "success") {
                setClients(data.detail);
            }

        })
        .catch(function (error) {
            setError({ msg: "Sorry, but we could not load the clients.", style: "red"})
        });

    }

    // Reset a user so they can take the questionnaire again
    const resetUsrPw = (uid,e) => {

        e.preventDefault()

        let passwordResetConfirmed = window.confirm("This will reset this user's password.  Do you wish to continue?")
        if(!passwordResetConfirmed){
            return
        }

        // If there is no cookie, then they are not logged in
        let authInfo = Encryption.extractHeaders(authCookie);

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + "/users/manager-password/" + uid, {
            method: 'get',
            headers: {
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            if (typeof data.rslt != "undefined" && data.rslt === "success") {
                setLightBoxView('resetPassword')
                setPasswordResetUrl(data.detail);
                toggleLightBoxOpen(true)
                return;
            }

            setLbError({ msg: "Sorry but there was an error resetting the user.", class: "flash-msg" });

        })
        .catch(function (error) {
            setError({ msg: "Sorry but there was an error resetting the user's password", style: "red" });
        });

    }

    const closeLightbox = (e) => {
        e.preventDefault();
        setLightBoxView(false)
        toggleLightBoxOpen(false)
        setLbError(false);
    }

    const setupPermissions = () => {
        let authInfo = Encryption.extractHeaders(authCookie);
        setPermissions(authInfo.permissions);
    }

    // Detect scrolls to the bottom of the page
    /* eslint-disable */
    useEffect(() => {
        window.onscroll = function () {
            if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 190) && loading === false && complete === false) {
                loadReports();
            }
        }
    }, []);

    useEffect(() => {
        loadReports();
        loadClients();
        setupPermissions();
    },[])
    /* eslint-enable */

    return (
        <ReportsList 
            updateFilters={updateFilters}
            loadReports={loadReports}
            loading={loading}
            error={error}
            reports={reports}
            clients={clients}
            filters={filters}
            resetUsrPw={resetUsrPw}
            lightBoxOpen={lightBoxOpen}
            lightboxView={lightboxView}
            passwordResetUrl={passwordResetUrl}
            lbError={lbError}
            toggleLightBoxOpen={toggleLightBoxOpen}
            permissions={permissions}
            closeLightbox={closeLightbox}
        />
    );
}

export default ReportsListContainer;
