import React from "react";
import { Helmet } from "react-helmet";

// View components

const Thanks = (props) => {

    console.log(props);

    return (
        <form id="pwForm">
            <Helmet>
                <title>Thank you :: The Coaching Hub</title>
            </Helmet>

            <div className="thanks-txt">
                <ol>
                    <li>The Coaching Hub team will assess your responses.</li>
                    <li>You will be matched with your ideal coach.</li>
                    <li>Your coach will be in to arrange a short introductory meeting.</li>
                    <li>Take the next steps on your professional journey with your accredited coach from The Coaching Hub</li>
                </ol>

                <p>If you have any questions, please get in touch via <a href="mailto:TheCoachingHub@abstractuk.co.uk" target="_blank" rel="noreferrer">TheCoachingHub@abstractuk.co.uk</a></p>
            </div>
        </form>
    );
}

export default Thanks;
