import React from 'react';
import Button from '../common/forms/button';
import TextInput from '../common/forms/text-input';
import { Link, Navigate } from 'react-router-dom';
import Checkbox from '../common/forms/checkbox';
import ValidationError from '../common/forms/validation-error';
import Error from '../common/forms/error';

function User(props){

    if (props.rdr) {
        return <Navigate to={props.rdr} />
    }

    let emailLinkPopup = null

    if(props.emailLink && props.lightBoxOpen){
        emailLinkPopup = <div className="cover">
            <Link to='/admin/users' className="close-btn">Close</Link>
            <div className="lb">
                <h3>Invite Link</h3>
                <div className="flash-msg grn">
                    The user has been created successfully.
                </div>
                <p>You can send the link below to the new user so they can set up a password for their account.</p>
                <div className="highlight">
                    <p className="lnk-wrap">{props.emailLink}</p>
                </div>
            </div>
        </div>
    }

    return (
        <div className='admin-content'>
            {emailLinkPopup}
            <section className='width-9 centred'>

                <h1>{props.isUserEdit ? 'Edit user details' : 'Create a new user'}</h1>

                <Error error={props.error}/>
                
                <TextInput label='First Name' id='firstName' value={props.settings.firstName} handleChange={(e) => props.handleUpdate('firstName',e) }/>
                <TextInput label='Last Name' id='lastName' value={props.settings.lastName} handleChange={(e) => props.handleUpdate('lastName',e) }/>
                <TextInput label='Email' id='email' value={props.settings.email} handleChange={(e) => props.handleUpdate('email',e) }/>
                <ValidationError errors={props.valErrors} fieldName='email'/>

                <div className="input select">
                    <label htmlFor='clientInput'>Client organisation</label>
                    <select id="clientInput" onChange={(e) => props.setClientId(e.target.value)} value={ props.clientId }>
                        <option value="0"></option>
                        {props.clients.map((client, i) =>
                            <option value={client.id} key={"copt-" + i}>{client.clientName}</option>
                        )}
                    </select>
                </div>
                
                <ValidationError errors={props.valErrors} fieldName='client' />

                <div className="permissions">
                    <h6>User permissions</h6>
                    <Checkbox label='Manage coaches' id='coaches' checked={props.permissions['Coaches']} handleChange={(e) => props.handlePermissionsUpdate('Coaches',e)}/>
                    <Checkbox label='Manage clients' id='clients' checked={props.permissions['Clients']}  handleChange={(e) => props.handlePermissionsUpdate('Clients',e)}/>
                    <Checkbox label='Manage admin users' id='users' checked={props.permissions['Users']}  handleChange={(e) => props.handlePermissionsUpdate('Users',e)}/>
                </div>

                {! props.isUserEdit ?
                <Checkbox label='Display the invitation link, so I can email it to the user myself' id='return_link' handleChange={(e) => props.setReturnEmailLink(e.target.checked)}/>
                 : null }
                <Button btnTxt={props.btnTxt} id='save_btn' handleClick={props.saveUser}/>
            </section>
  
        </div>
    )
}

export default User