// Core react and plugins
import React, { useState } from 'react';

// View components
import UsrSettings from '../../../components/users/auth/usr-settings';

const UsrSettingsContainer = (props) => {

    // State management
    const [tab, setTab] = useState("settings");

    return (
        <UsrSettings
            tab={tab}
            setTab={setTab}
        />
    );
}

export default UsrSettingsContainer;
