import React from 'react';
import { Routes, Route  } from 'react-router-dom'

import LogoutContainer from './../containers/users/auth/logout-container';
import ForgottenPwContainer from './../containers/users/auth/forgotten-pw-container';
import SetPwContainer from './../containers/users/auth/set-pw-container';
import VerifyContainer from '../containers/users/auth/verify-container';
import LandingContainer from '../containers/landing/landing-container';
import HeaderContainer from '../containers/common/header-container';
import FooterContainer from '../containers/common/footer-container';
import LoginContainer from '../containers/users/auth/login-container';
import QuestionnaireContainer from '../containers/questionnaire/questionnaire-container';

function Routing(props) {
       
        return (
            <div className="external">

                <HeaderContainer isLoggedIn={ props.loginStatus ? true : false } isAdmin={ props.loginStatus === "admin" ? true : false } isExternal={true} />

                <Routes>
                    <Route path='t/:token/:key' element={<LandingContainer />} />
                    <Route path='q/*' element={<QuestionnaireContainer setLoginStatus={ props.setLoginStatus } />} />

                    <Route path='forgotten-password' element={ <ForgottenPwContainer/> } />
                    <Route path='set-password/:resetToken/:resetKey/:setType' element={ <SetPwContainer/> } />
                    <Route path='set-password/:resetToken/:resetKey' element={<SetPwContainer />} />
                    <Route path='verify/:token/:mash' element={ <VerifyContainer /> } />        
                    <Route path='logout' element={<LogoutContainer setLoginStatus={props.setLoginStatus} />} />
                    <Route exact path='login' element={<LoginContainer />} />
                    <Route exact path='/' element={ <LandingContainer/> } />
                </Routes>

                <FooterContainer />

            </div>
        );

}

export default Routing;
